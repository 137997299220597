import React from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'

import {hexToRGBAString} from '@daedalus/core/src/utils/css'

type ToasterMessagePropsType = {
  children: React.ReactNode
}

const Wrapper = styled('div')(
  ({theme}) => css`
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 9999;
    overflow: hidden;
    background: ${hexToRGBAString(theme.colors.background.neutral.c000, 0.4)};
  `
)

const Message = styled('div')(
  ({theme}) => css`
    color: ${theme.colors.background.neutral.c000};
    line-height: ${theme.fonts.lineHeight.lg};
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: ${theme.colors.background.neutral.c600};
    padding: ${theme.layout.spacing.s500}px;
  `
)

const ToasterMessage = ({children}: ToasterMessagePropsType) => {
  return (
    <Wrapper>
      <Message>{children}</Message>
    </Wrapper>
  )
}

export default ToasterMessage
