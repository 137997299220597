import React from 'react'
import {toggle} from 'opticks'

// import {getCurrencyCode} from 'modules/meta/selectors'
import {Avatar, AvatarType} from '@daedalus/atlas/Avatar'
import {GemsTextTag} from '@daedalus/atlas/helpers/GemsTextTag'
import {getUserName} from '@daedalus/core/src/auth/business/authentication'
import {User} from '@daedalus/core/src/auth/types/Cognito'

type Props = {
  user: User | null
  hasGemsTag?: boolean
}

export const UserAvatar = ({user, hasGemsTag = true}: Props) => {
  const picture = user?.attributes?.picture

  return (
    <Avatar
      picture={picture}
      type={picture ? AvatarType.Picture : AvatarType.Icon}
      name={getUserName(user || null)}
      bottomIcon={toggle(
        'c9f92dea-new-tags-poc',
        hasGemsTag && <GemsTextTag hasBorder />,
        null
      )}
      borderColorPath="border.special.c300"
    />
  )
}
