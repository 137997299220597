import {createSelector} from '@reduxjs/toolkit'
import {pathOr} from 'ramda'
import {RootState} from 'store'

import {defaultCountry} from '@daedalus/core/src/localization/business/countries'
import {HotelFeesBreakdown} from '@daedalus/core/src/offer/types/offer'
import {Room} from '@daedalus/core/src/room/types/room'
import {searchApi} from '@daedalus/core/src/sapi/services/searchApi'

const pathToRoomsList = ['rooms', 'roomsList']

type Selector<S> = (state: RootState) => S

// @ts-expect-error RTK Query serializes the request params and store in the Redux module
export const getRoomsState = searchApi.endpoints.getRooms.select()

export const getRooms: Selector<Room[]> = createSelector(
  (state: RootState) => {
    return pathOr<Room[]>([], pathToRoomsList, state)
  },
  rooms => rooms
)

export const nightlyDisplayTotalPath = ['chargeable', 'nightlyDisplayTotal']

export const basePath = ['chargeable', 'base']
export const nightlyBasePath = ['chargeable', 'nightlyBase']

export const taxesPath = ['chargeable', 'taxes']
export const nightlyTaxesPath = ['chargeable', 'nightlyTaxes']

/* TODO: remove total paths after BoFH remove these properties from the api */
export const nightlyTotalPath = ['chargeable', 'nightlyTotal']

export const hotelFeesPath = ['hotelFees', 'total']

//has some more levels, tbd later
export const getHotelFees = pathOr(0, hotelFeesPath)

export const hotelFeesNightlyPath = ['hotelFees', 'nightly']

export const hotelFeesBreakdownPath = ['hotelFees', 'breakdown']

//has some more levels, tbd later
export const getHotelFeesBreakdown = pathOr<HotelFeesBreakdown>(
  [],
  hotelFeesBreakdownPath
)

export const getTaxDisplayLogic = createSelector(
  (state: RootState) => state.rooms,
  (state: RootState['rooms']) => {
    const defaultTaxDisplayLogic = {
      includeLocalTaxes: defaultCountry.includeLocalTaxes,
      includeTaxes: defaultCountry.includeTaxes
    }

    return state?.taxDisplayLogic || defaultTaxDisplayLogic
  }
)
