/* eslint-disable fp/no-mutating-methods */
import React, {ReactNode} from 'react'
import {useHistory} from 'react-router-dom'

import {parseQueryString} from '../../../utils/url'
import {ReactRouterUrl, RouterContext, Url} from './RouterContext'

export interface Props {
  /**
   * The children component to render
   */
  children: ReactNode
}

/**
 * This router provider will be used as a temporary abstraction
 * to facilitate our migration to Next.js.
 * This should be deleted as we fully migrate the app to the framework.
 *
 * Use this provider in apps that use react-router routing
 */
export const RouterProvider = ({children}: Props) => {
  const history = useHistory()

  const push = (url: Url) => history.push(url as ReactRouterUrl)
  const replace = (url: Url) => history.replace(url as ReactRouterUrl)
  const goBack = () => history.goBack()
  const getQueryParams = () => parseQueryString(location.search)

  return (
    <RouterContext.Provider value={{push, replace, goBack, getQueryParams}}>
      {children}
    </RouterContext.Provider>
  )
}
