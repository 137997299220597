import {StatusType} from '@datadog/browser-logs'
import {Middleware} from '@reduxjs/toolkit'

import {
  getGoogleOneTapSignedIn,
  removeGoogleOneTapSignedIn
} from '../../../auth/business/persistData'
import {
  trackLoginClick,
  trackSignUpClick,
  trackUserAuthClick
} from '../../../auth/modules/actions'
import {
  getCognitoUserId,
  getCognitoUserTier
} from '../../../auth/modules/helpers'
import {
  getAuthenticationMethod,
  getCognitoHasPlusTier
} from '../../../auth/modules/selectors'
import {redirectUsersFromCallbackUrl} from '../../../auth/modules/thunks'
import {User} from '../../../auth/types/Cognito'
import {getAuthenticationFlow} from '../../../auth/utils/storage'
import {dataDogLogEvent} from '../../components/DataDogClient'
import {Action, Category, Entity, Team} from '../../types/Events'
import AnalyticsUtils, {isGoodBot} from '../../utils'
import {getFullStoryObject} from '../../utils/FullStory'
import {trackEvent} from '../actions'

/**
 * Fires an action to tracks that the user initiated a sign in process
 * @param metric - The event name
 * @param metadata - Extra event data
 * @param level - Type of the event from StatusType
 */
export const logEvent = (
  metric: string,
  metadata: Record<string, unknown> | undefined = undefined,
  level: StatusType = StatusType.info
) => {
  dataDogLogEvent(metric, metadata, level)
}

export const trackFullStoryEvent = (
  eventName: string,
  eventProperties: Record<string, unknown>
) => {
  getFullStoryObject()?.event(eventName, eventProperties)
}

/**
 * Middleware to track all kinds of configured events
 */

const middleware: Middleware = store => next => async action => {
  const {dispatch} = store
  const state = store.getState()

  // TODO: The good bots are currently only filtered out for Search
  if (
    !AnalyticsUtils.analyticsPlatformEnabled() ||
    isGoodBot(state.meta?.userAgent)
  )
    return next(action)

  switch (action.type) {
    /** Log user authentication click inside the overlay */
    case trackUserAuthClick.type: {
      const authenticationMethod = action?.payload?.authenticationMethod
      const sourceComponent = action?.payload?.sourceComponent
      const analyticsData = action?.payload?.analyticsData ?? {}

      dispatch(
        trackEvent({
          category: Category.User,
          entity: Entity.Authentication,
          action: Action.Clicked,
          component: sourceComponent,
          payload: {
            authenticationMethod,
            ...analyticsData
          },
          team: Team.Retention
        })
      )
      break
    }

    /** Log places where user open the auth overlay with a Signup button */
    case trackSignUpClick.type: {
      dispatch(
        trackEvent({
          category: Category.User,
          entity: Entity.Signup,
          action: Action.Clicked,
          component: action?.payload,
          team: Team.Retention
        })
      )
      break
    }

    /** Log places where user open the auth overlay with a Login button */
    case trackLoginClick.type: {
      dispatch(
        trackEvent({
          category: Category.User,
          entity: Entity.Login,
          action: Action.Clicked,
          component: action?.payload,
          team: Team.Retention
        })
      )
      break
    }

    case redirectUsersFromCallbackUrl.fulfilled.type: {
      const user = action.payload as User
      const userId = getCognitoUserId(user)
      const memberPlus = getCognitoHasPlusTier(getCognitoUserTier(user))
      const authenticationMethod = getAuthenticationMethod(state)
      const hasUsedOneTap = getGoogleOneTapSignedIn()
      const authenticationFlow = getAuthenticationFlow()

      const payload: Record<string, unknown> = {
        userId,
        authenticationMethod,
        authenticationFlow
      }

      if (authenticationMethod === 'Google') {
        // eslint-disable-next-line fp/no-mutation
        payload.googleMethod = hasUsedOneTap ? 'OneTap' : 'regular'
      }

      dispatch(
        trackEvent({
          category: Category.System,
          entity: Entity.Authentication,
          action: Action.Succeeded,
          payload,
          team: Team.Retention
        })
      )

      removeGoogleOneTapSignedIn()

      AnalyticsUtils.identify(
        userId as string,
        {memberPlus} as {memberPlus?: boolean}
      )
      break
    }

    default: {
      break
    }
  }

  return next(action)
}

export default middleware
