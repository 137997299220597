import React from 'react'
import {useSelector} from 'react-redux'
import {css} from '@emotion/react'
import styled from '@emotion/styled'

import {Avatar, AvatarType} from '@daedalus/atlas/Avatar'
import {cssTheme} from '@daedalus/atlas/themes'
import {mq} from '@daedalus/atlas/utils/breakpoints'
import {useBrand} from '@daedalus/core/src/_web/brand/hooks/useBrand'
import {getUserName} from '@daedalus/core/src/auth/business/authentication'
import {getUser} from '@daedalus/core/src/auth/modules/selectors'

import {PrivateDealTag} from '../../../privateDeal/PrivateDealTag'

type Props = {
  hasBottomIcon?: boolean
}

const ImageContainer = styled('div')`
  display: flex;
  justify-content: center;

  ${mq.desktopXs(css`
    margin: ${cssTheme.layout.spacing.s700} auto;
  `)}
`

const AvatarWrapper = styled.div`
  position: relative;
`

const getAvatarType = (picture?: string): AvatarType =>
  (picture ? AvatarType.Picture : AvatarType.Icon) as AvatarType

export const ProfilePicture = ({hasBottomIcon = true}: Props) => {
  const user = useSelector(getUser)
  const {brandCode, brandPrivateDealMessage} = useBrand()
  const profilePicture = user?.attributes?.picture

  return (
    <ImageContainer>
      <AvatarWrapper>
        <Avatar
          picture={profilePicture}
          name={getUserName(user)}
          size="lg"
          type={getAvatarType(profilePicture)}
          borderColorPath="border.special.c300"
          bottomIcon={
            hasBottomIcon ? (
              <PrivateDealTag
                brandCode={brandCode}
                message={brandPrivateDealMessage}
              />
            ) : undefined
          }
        />
      </AvatarWrapper>
    </ImageContainer>
  )
}
