import {
  EventSpace,
  trackEventV1Type
} from 'modules/analytics/actions/trackEventV1'
import {pickAll} from 'ramda'
import {CONFIRMATION_PAGE_PATH, PAYMENT_PAGE_PATH} from 'routes'
import {getLastVisitedPage} from 'utils/updateHistoryLocation'

import {TrackEventProperties} from '@daedalus/core/src/analytics/types/Events'
import {UrlCheckoutParamsType} from '@daedalus/core/src/utils/url/types/UrlParams'

export interface TrackEventMetaParams {
  searchId?: string
}

export const getMetadata = (
  urlParams: UrlCheckoutParamsType | undefined,
  {searchId}: TrackEventMetaParams = {}
) => {
  // TODO: this should be id of the signed in user
  const userId = ''
  const viewPortHeight = window.innerHeight
  const viewPortWidth = window.innerWidth
  const lastPage = getLastVisitedPage()

  const urlParamsMetadata: Partial<UrlCheckoutParamsType> = pickAll(
    [
      'checkIn',
      'checkOut',
      'currency',
      'userIp',
      'anonymousId',
      'deviceType',
      'locale',
      'providerCode',
      'rooms',
      'hotelId',
      'userCountry',
      'providerRateType',
      'redirectId',
      'userAgent'
    ],
    urlParams
  )

  return {
    ...urlParamsMetadata,
    searchId: searchId || '',
    userId,
    viewPortHeight,
    viewPortWidth,
    lastPage
  }
}

export const getEventProperties = (
  event: trackEventV1Type,
  metaParams: TrackEventMetaParams = {}
) => {
  const {urlParams} = event

  const meta = getMetadata(
    urlParams,
    metaParams
  ) as unknown as TrackEventProperties
  const eventProps: trackEventV1Type = pickAll(
    ['category', 'space', 'action', 'label', 'value', 'context', 'payload'],
    event
  )

  return {
    ...eventProps,
    meta
  } as unknown as TrackEventProperties
}

export const getPageSpace = (pathname: string) => {
  // eslint-disable-next-line default-case
  switch (pathname) {
    case PAYMENT_PAGE_PATH: {
      return EventSpace.PAYMENT_PAGE
    }

    case CONFIRMATION_PAGE_PATH: {
      return EventSpace.BOOKING_CONFIRMATION_PAGE
    }
  }
}
