import React, {lazy, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Route, Switch} from 'react-router-dom'
import Settings from 'Settings'
import {getAccommodationUrlPersistingParams} from 'utils/urls'

import {getAnonymousId} from '@daedalus/core/src/_web/anonymousId'
import {getBrand} from '@daedalus/core/src/_web/brand/modules/selectors'
import {withLoadingRetry} from '@daedalus/core/src/_web/router/utils/withLoadingRetry'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  Category,
  Entity
} from '@daedalus/core/src/analytics/types/Events'
import {CognitoLogoutHandler} from '@daedalus/core/src/auth/components/CognitoLogoutHandler'
import {
  COGNITO_LOGIN_CALLBACK_ROUTE,
  COGNITO_LOGOUT_CALLBACK_ROUTE
} from '@daedalus/core/src/auth/constants'
import {TRACKING_TIMEOUT_BEFORE_NAVIGATING_AWAY} from '@daedalus/core/src/auth/modules/actions'
import {getDataFile} from '@daedalus/core/src/experiments/modules/selectors'
import {DebugPanelLoader, DebugPanelModules} from '@daedalus/debug-panel'
import {CognitoPasswordlessLoader} from '@daedalus/shared/src/authentication/CognitoPasswordlessLoader'

export const ROOM_SELECTION_PAGE_PATH = '/room-selection/'
export const PAYMENT_PAGE_PATH = '/checkout/'
export const CONFIRMATION_PAGE_PATH = '/confirmation/'

const APP_ENV = Settings.get('REACT_APP_ENV')

const DebugPanelWithOptimizelyExperiments = () => {
  const datafile = useSelector(getDataFile)
  const optimizelyFlags = datafile?.featureFlags?.map(({key}) => key) || []
  const anonymousId = useSelector(getAnonymousId)

  return (
    <DebugPanelLoader
      protectCode={[0, 0, 1, 1, 0, 1]}
      thirdPartyExperiments={optimizelyFlags}
      meta={{anonymousId}}
      modulesConfig={[
        DebugPanelModules.EXPERIMENTS,
        DebugPanelModules.SETTINGS
      ]}
    />
  )
}

const Confirmation = lazy(async () =>
  withLoadingRetry(
    () =>
      import(
        'views/Confirmation'
        /* webpackChunkName: "confirmation" */
      )
  )
)

const Payment = lazy(async () =>
  withLoadingRetry(
    () =>
      import(
        'views/Payment'
        /* webpackChunkName: "payment" */
      )
  )
)

const RedirectToSearch = () => {
  const dispatch = useDispatch()
  const brand = useSelector(getBrand)

  dispatch(
    trackEvent({
      category: Category.System,
      entity: Entity.Page,
      action: Action.Errored,
      payload: {
        errorMessage: `Page not found: ${window.location.href}`
      }
    })
  )

  // Add a small timeout before redirect to make sure that event is sent
  setTimeout(() => {
    window.location.replace(brand.address)
  }, TRACKING_TIMEOUT_BEFORE_NAVIGATING_AWAY)

  return null
}

const RedirectToAccommodationPage = () => {
  const brand = useSelector(getBrand)
  useEffect(() => {
    const redirectUrl = getAccommodationUrlPersistingParams(
      brand,
      window.location.search
    )
    if (redirectUrl) window.location.replace(redirectUrl)
    else window.location.replace(brand.address)
  }, [brand])

  return null
}

const Routes = () => {
  return (
    <Switch>
      <Route component={Confirmation} path={CONFIRMATION_PAGE_PATH} />
      <Route component={Payment} path={PAYMENT_PAGE_PATH} />
      <Route
        component={RedirectToAccommodationPage}
        path={ROOM_SELECTION_PAGE_PATH}
      />
      <Route
        component={CognitoPasswordlessLoader}
        path={COGNITO_LOGIN_CALLBACK_ROUTE}
      />
      <Route
        component={CognitoLogoutHandler}
        path={COGNITO_LOGOUT_CALLBACK_ROUTE}
      />
      <Route path="/*debugPanel">
        <DebugPanelWithOptimizelyExperiments />
      </Route>
      {/* Redirect to website homepage when landing on checkout with incorrect url path. Not needed on non-prod envs */}
      {APP_ENV === 'production' && (
        <Route path="*" component={RedirectToSearch} />
      )}
    </Switch>
  )
}

export default Routes
