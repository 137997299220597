import React, {useCallback, useRef, useState} from 'react'

import {Button} from '@daedalus/atlas/Button'
import {DropDownMenu, DropDownMenuLink} from '@daedalus/atlas/DropDownMenu'
import {Page} from '@daedalus/core/src/analytics/types/Events'
import {ShowPasswordlessLoginPayload} from '@daedalus/core/src/auth/types/Auth'
import {User} from '@daedalus/core/src/auth/types/Cognito'
import {FormattedMessageWrapper} from '@daedalus/core/src/localization/components/FormattedMessage'

import {SignInButton} from '../SignInButton'
import {UserAvatar} from '../UserAvatar'

interface Props {
  /** Whether the user is authenticated */
  isAuthenticated: boolean
  /** Callback fired when the user tries to sign in */
  onSignIn: ({
    sourceComponent,
    flow
  }: {
    sourceComponent: ShowPasswordlessLoginPayload['sourceComponent']
    flow: ShowPasswordlessLoginPayload['flow']
  }) => void
  /** User object with metadata such as profile picture, name etc. */
  user: User | null | undefined
  /** Callback fired when the user tries to log out */
  logOut?: () => void
  /** Url of users account page */
  accountUrl?: string
  /** Url of users bookings page */
  myBookingsUrl?: string
  /** Whether to show the gems tag, useful for websites other than vio.com */
  hasGemsTag?: boolean
  /** Slot for the Get The App link */
  getTheAppSlot?: React.ReactNode
  // Added as part of 4728f379-vio-color-restyle
  page?: Page
}

export const HeaderUserWidget = ({
  isAuthenticated,
  onSignIn,
  user,
  logOut,
  accountUrl,
  myBookingsUrl,
  hasGemsTag = true,
  getTheAppSlot = null,
  page
}: Props) => {
  const [popoverOpen, setPopoverOpen] = useState(false)

  const togglePopover = useCallback(() => {
    setPopoverOpen(!popoverOpen)
  }, [popoverOpen, setPopoverOpen])

  const anchorRef = useRef(null)

  const handleLogOutClick = useCallback(() => {
    if (logOut) logOut()
  }, [logOut])

  const AuthenticatedMenu = () => (
    <>
      {accountUrl && (
        <DropDownMenuLink url={accountUrl}>
          <FormattedMessageWrapper
            dataId="AccountProfileLink"
            id="manageAccount"
            defaultMessage="Manage account"
          />
        </DropDownMenuLink>
      )}
      {myBookingsUrl && (
        <DropDownMenuLink url={myBookingsUrl}>
          <FormattedMessageWrapper
            dataId="MyBookingsPageLink"
            id="myBookings"
            defaultMessage="My bookings"
          />
        </DropDownMenuLink>
      )}
      {getTheAppSlot}
      <DropDownMenuLink onClick={handleLogOutClick}>
        <FormattedMessageWrapper
          dataId="LogOutProfileLink"
          id="userWidget.signOut"
          defaultMessage="Sign out"
        />
      </DropDownMenuLink>
    </>
  )

  return isAuthenticated ? (
    <div>
      <Button
        ref={anchorRef}
        variant="quiet"
        dataId="UserWidget"
        className="fs-exclude"
        onClick={togglePopover}
        iconStart={<UserAvatar user={user || null} hasGemsTag={hasGemsTag} />}
      >
        <span>
          {user?.attributes?.given_name ? (
            user?.attributes?.given_name
          ) : (
            <FormattedMessageWrapper
              id="userWidget.myProfile"
              defaultMessage="My profile"
            />
          )}
        </span>
      </Button>
      <DropDownMenu
        isOpen={popoverOpen}
        anchorRef={anchorRef}
        onClose={togglePopover}
      >
        <AuthenticatedMenu />
      </DropDownMenu>
    </div>
  ) : (
    <SignInButton onSignIn={onSignIn} page={page} />
  )
}
