import React from 'react'
import {FallbackProps} from 'react-error-boundary'
import {ThemeProvider} from '@emotion/react'
import styled from '@emotion/styled'

import GridGlobalStyles from '@daedalus/atlas/foundation/GridGlobalStyles'
import {CssBaseline} from '@daedalus/atlas/helpers/CssBaseline'
import {getTheme, ThemeNames} from '@daedalus/atlas/themes'

import {CrashErrorMessageContainer, ErrorMessage} from '../CrashErrorMessage'

const HeightWrapper = styled('div')`
  min-height: 600px;
  height: 1px;
`

interface Props extends FallbackProps {
  onClick?: () => void
  t?: ErrorMessage
}

export const ErrorBoundaryFallback: React.FC<Props> = ({onClick, t}) => {
  const theme = getTheme(ThemeNames.vio)

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GridGlobalStyles />
      <HeightWrapper>
        <CrashErrorMessageContainer t={t} onClick={onClick} />
      </HeightWrapper>
    </ThemeProvider>
  )
}
