import {getHttpRequestId} from 'modules/common/selectors'
import {existingBookingOfferCheckDataTransform} from 'modules/offerCheck/utils'
import {pathOr} from 'ramda'
import bofhDefaultHeaders from 'services/bofhDefaultHeaders'
import Settings from 'Settings'
import {RootState} from 'store'
import {urlParams} from 'utils/urlParams'

import {
  getBrandCode,
  State as MetaState
} from '@daedalus/core/src/_web/brand/modules/selectors'
import {getCookie} from '@daedalus/core/src/_web/utils/cookies'
import {bookingRetrieveApi} from '@daedalus/core/src/booking/services/bookingRetrieveApi'
import {getBovioApiKey} from '@daedalus/core/src/booking/utils'
import {defaultLanguage} from '@daedalus/core/src/localization/services/locale'
import {getUserDeviceDetails} from '@daedalus/core/src/utils/userAgent'

export const bookingResponse = (state: RootState) =>
  state?.book?.booking?.response

export const getBookingModuleStatus = (state: RootState): string =>
  state?.book?.offerBookState || ''

export const getExistingBookingQueryParams = (state: RootState) => {
  const httpRequestId = getHttpRequestId(state)
  const params = urlParams()
  const {locale: urlLocale, bookingId = ''} = params
  const locale = urlLocale || getCookie('locale') || defaultLanguage
  const {deviceCategory: deviceType} = getUserDeviceDetails()
  const brandCode = getBrandCode(state as MetaState)
  const appEnv = Settings.get('REACT_APP_ENV')
  const profile = getBovioApiKey({
    brandCode: brandCode,
    appEnv,
    urlParams: params
  })
  const headers = {
    ...bofhDefaultHeaders,
    'X-Request-Id': httpRequestId,
    'X-Api-Key': profile
  }
  const boVioEndpoint = Settings.get('REACT_APP_BOFH_ENDPOINT')

  return {
    url: boVioEndpoint,
    bookingId,
    deviceType,
    locale,
    headers
  }
}

export const getBundleBookings = (state: RootState) => {
  const {bookingId} = urlParams()
  const [bookingId1, bookingId2] = bookingId?.split(',') || []
  const params = getExistingBookingQueryParams(state)

  const {data: booking1} = bookingRetrieveApi.endpoints.getBookingById.select({
    ...params,
    bookingId: bookingId1
  })(state)

  const {data: booking2} = bookingRetrieveApi.endpoints.getBookingById.select({
    ...params,
    bookingId: bookingId2
  })(state)

  if (booking1 && booking2) {
    const sortedBookings = [booking1, booking2].sort((a, b) => {
      const date1 = new Date(a.checkIn)
      const date2 = new Date(b.checkIn)

      return date1.getTime() - date2.getTime()
    })
    return existingBookingOfferCheckDataTransform(sortedBookings)
  }

  return undefined
}

export const getCountryCode = (state: RootState) => {
  return pathOr(
    '',
    [
      'book',
      'booking',
      'request',
      'payment',
      'billing',
      'address',
      'countryCode'
    ],
    state
  )
}
