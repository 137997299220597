import React from 'react'
import {useDispatch} from 'react-redux'
import {toggle} from 'opticks'

import {Button, Variant} from '@daedalus/atlas/Button'
import {Icon} from '@daedalus/atlas/Icon'
import {Page} from '@daedalus/core/src/analytics/types/Events'
import {trackLoginClick} from '@daedalus/core/src/auth/modules/actions'
import {
  AuthenticationFlows,
  ShowPasswordlessLoginPayload,
  SourceComponentType
} from '@daedalus/core/src/auth/types/Auth'
import {FormattedMessageWrapper} from '@daedalus/core/src/localization/components/FormattedMessage'

interface Props {
  onSignIn: ({
    sourceComponent,
    flow
  }: {
    sourceComponent: ShowPasswordlessLoginPayload['sourceComponent']
    flow: ShowPasswordlessLoginPayload['flow']
  }) => void
  // Added as part of 4728f379-vio-color-restyle
  page?: Page
}

export const SignInButton = ({onSignIn, page}: Props) => {
  const dispatch = useDispatch()

  const component = SourceComponentType.Header

  const handleSingInClick = () => {
    dispatch(trackLoginClick(component))

    onSignIn({
      sourceComponent: component,
      flow: AuthenticationFlows.SignUp
    })
  }

  return (
    <Button
      variant={toggle<Variant[]>(
        '4728f379-vio-color-restyle',
        'secondaryGradient',
        page === Page.Home ? 'secondary' : 'quiet'
      )}
      isRounded={toggle('4728f379-vio-color-restyle', true, page === Page.Home)}
      size={'md'}
      dataId="SignInButton"
      onClick={handleSingInClick}
      iconStart={<Icon name="Unlock" />}
    >
      <FormattedMessageWrapper
        id="userWidget.signIn"
        defaultMessage="Sign in"
      />
    </Button>
  )
}
