import isString from 'lodash/isString'
import {parse} from 'qs'
import {CookieConfig} from 'types/Cookie'

import {getCookie, setCookie} from '../../utils/cookies'
import userCountryCodeCookieConfig from './cookieConfig'

export const getCountryCodeFromQueryString = (): string | null => {
  const {userCountry} = parse(location.search, {
    ignoreQueryPrefix: true
  })

  return isString(userCountry) ? userCountry : null
}

const getCookieOptions = (cookieConfig: CookieConfig) => {
  const {secure, sameSite, maxAge: expires, domain} = cookieConfig.options
  return {
    expires,
    secure,
    sameSite,
    domain
  }
}

export const getPersistedUserCountryCode = () =>
  getCookie(userCountryCodeCookieConfig.name)

export const persistUserCountryCode = (userCountryCode: string) =>
  setCookie(
    userCountryCodeCookieConfig.name,
    userCountryCode,
    getCookieOptions(userCountryCodeCookieConfig)
  )
