import axios from 'axios'
import Settings from 'Settings'
import {PaymentOptionsResponseType} from 'types/Rooms'
import setHttpRequestId from 'utils/setHttpRequestId'

import {getPersistedSapiLabelWithFallback} from '@daedalus/core/src/_web/sapiLabel/business'
import {LinkType} from '@daedalus/core/src/booking/types/Link'
import {getBovioApiKey} from '@daedalus/core/src/booking/utils'
import {BrandCode} from '@daedalus/core/src/brand/types'
import {isReactNativeWebView} from '@daedalus/core/src/native'
import {getFriendAndFamilyCugType} from '@daedalus/core/src/offer/business/getFriendAndFamilyCugType'
import {getPlusCugType} from '@daedalus/core/src/offer/business/getPlusCugType'
import {
  MOBILE_APP_CUG_TYPE,
  OFFLINE_CUG_TYPE,
  SENSITIVE_CUG_TYPE,
  SIGNED_IN_CUG_TYPE
} from '@daedalus/core/src/offer/business/privateDeals'
import {getSapiClient} from '@daedalus/core/src/sapi/services'
import {sapiErrorToCommonError} from '@daedalus/core/src/sapi/services/error'
import {getUserTierForSapi} from '@daedalus/core/src/sapi/utils'
import {getQueryStringObject} from '@daedalus/core/src/utils/url'
import {UrlCheckoutParamsType} from '@daedalus/core/src/utils/url/types/UrlParams'

import bofhDefaultHeaders from '../bofhDefaultHeaders'

const axiosConfig = {
  headers: bofhDefaultHeaders
}

const axiosInstance = axios.create(axiosConfig)
const appEnv = Settings.get('REACT_APP_ENV')

export const getPaymentOptionsFactory =
  httpService =>
  async (
    link: LinkType,
    httpRequestId: string,
    brandCode: BrandCode
  ): Promise<PaymentOptionsResponseType> => {
    const {href} = link
    setHttpRequestId(httpService, httpRequestId)
    const apiKey = getBovioApiKey({
      brandCode,
      appEnv,
      urlParams: getQueryStringObject()
    })
    httpService.defaults.headers.common['X-Api-Key'] = apiKey

    return httpService.get(href)
  }

interface FetchRoomsSapiParams {
  hotelId: string
  checkIn: string
  checkOut: string
  rooms: string
  providerCode: string
  isMemberPlus: boolean
  isEmployee: boolean
  isAuthenticated: boolean
  oid: string
  baseRate: string
  localTax: string
  tax: string
  useClickedOfferParams: boolean
  urlParams?: UrlCheckoutParamsType
  userId?: string
  emailDomain?: string
  searchId?: string
  deviceId?: string
  bundleId?: string
}

export const fetchRoomsSapi = async ({
  hotelId,
  checkIn,
  checkOut,
  bundleId,
  rooms,
  providerCode,
  isMemberPlus,
  isEmployee,
  isAuthenticated,
  oid,
  baseRate,
  localTax,
  tax,
  useClickedOfferParams,
  urlParams,
  userId,
  emailDomain,
  searchId,
  deviceId
}: FetchRoomsSapiParams) => {
  try {
    const defaultCugDeals = [
      OFFLINE_CUG_TYPE,
      SIGNED_IN_CUG_TYPE,
      SENSITIVE_CUG_TYPE,
      ...(isReactNativeWebView() ? [MOBILE_APP_CUG_TYPE] : [])
    ]
    const plusCugType = getPlusCugType(isMemberPlus, urlParams)
    const FsfCugType = getFriendAndFamilyCugType(isEmployee)
    const cugDeals = [...defaultCugDeals, ...plusCugType, ...FsfCugType].join(
      ','
    )
    const tier = getUserTierForSapi({isAuthenticated, isMemberPlus, urlParams})

    const label = getPersistedSapiLabelWithFallback()

    const clickedOfferParams = useClickedOfferParams
      ? {
          clickedOfferId: oid,
          clickedOfferBaseRate: baseRate && Number.parseFloat(baseRate),
          clickedOfferTaxes: tax && Number.parseFloat(tax),
          clickedOfferHotelFees: localTax && Number.parseFloat(localTax),
          bundleId
        }
      : {}

    const sapiClient = await getSapiClient()
    const response = await sapiClient.rooms({
      hotelId,
      checkIn,
      checkOut,
      rooms,
      providerCode,
      cugDeals,
      tier,
      userId,
      emailDomain,
      searchId,
      label,
      deviceId,
      ...clickedOfferParams
    })
    return {
      data: response
    }
  } catch (error) {
    const sapiError = await sapiErrorToCommonError(error)
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw sapiError
  }
}

const fetchPaymentOptionsService = getPaymentOptionsFactory(axiosInstance)

export {fetchPaymentOptionsService}
