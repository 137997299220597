import {toggle} from 'opticks'

import {Booking} from '../api-types/bovio/response/booking'
import {brands} from '../brand/config'
import {BrandCode} from '../brand/types'
import {Offer} from '../offer/types/offer'
import {OfferCheckAPIResponseWithAdaptedRoom} from '../offer/types/OfferCheck'
import {addDaysToDate} from '../utils/date'
import {getIsProductionAppEnv} from '../utils/environment'

/**
 * Returns BoVio profile API key. The key is different for each brand and app environment.
 * Can be forced with forceBovioApiKey query url param
 */
export const getBovioApiKey = ({
  brandCode,
  appEnv,
  urlParams
}: {
  brandCode: BrandCode
  appEnv: string
  urlParams: Record<string, unknown>
}): string => {
  const BOFH_PROFILE_CL_EXPERIMENT_STAGING =
    'VXBkYXRlIENMIGVsaWdpYmlsaXR5IHJ1bGVz_test'
  const BOFH_PROFILE_CL_EXPERIMENT_PROD = 'VXBkYXRlIENMIGVsaWdpYmlsaXR5IHJ1bGVz'
  const isProdEnv = getIsProductionAppEnv(appEnv)

  if (!isProdEnv && urlParams.forceBovioApiKey) {
    return String(urlParams.forceBovioApiKey)
  }

  const profileKeyName = isProdEnv ? 'bovioProdApiKey' : 'bovioStagingApiKey'
  const profileKey =
    brands[brandCode]?.[profileKeyName] ||
    (brands.vio[profileKeyName] as string)

  if (brandCode === 'vio' && toggle('169ce369-cl-eligibility', false, true)) {
    return isProdEnv
      ? BOFH_PROFILE_CL_EXPERIMENT_PROD
      : BOFH_PROFILE_CL_EXPERIMENT_STAGING
  }
  return profileKey
}

// A type-guard function that checks if the given argument is valid booking data
export const isExistingBooking = (
  bookingData?: OfferCheckAPIResponseWithAdaptedRoom | Offer | Booking
): bookingData is Booking => {
  return !!(bookingData as Booking)?.confirmationId
}

interface BookAgainDates {
  bookAgainCheckIn: string
  bookAgainCheckOut: string
}

/**
 * Returns a consequent check in and check out date when provided the original checkout date
 * ie.: 2022-07-23
 * @param checkOutDate - The string that represents a date when a stay ends
 * @returns -
 */
export const getBookAgainCheckInCheckOutDates = (
  checkOutDate: string
): BookAgainDates => {
  const bookAgainCheckIn = checkOutDate
  const bookAgainCheckOut = addDaysToDate(bookAgainCheckIn, 1)
  return {bookAgainCheckIn, bookAgainCheckOut}
}
