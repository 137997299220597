/**
 * Converts color format from HEX string to [R, G, B] array
 *
 * @param string - string describes color in HEX format, e.g. #ff44ed
 *
 * @returns Array of R, G and B
 */
const hexToRGB = (hex: string): number[] => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? [
        Number.parseInt(result[1], 16),
        Number.parseInt(result[2], 16),
        Number.parseInt(result[3], 16)
      ]
    : []
}

/**
 * Converts color format in the following way:
 * - from HEX string to "R,G,B,A" string applying the given opacity
 * - from CSS variable to "color-mix(in srgb, <COLOR>, <OPACITY-PERCENTAGE>, transparent)"
 *
 * @param Color - {@link Color} object containing HEX format color (e.g. #ff44ed) and the opacity for the color
 *
 * @returns A `string` containing R, G, B and, A divided by comma or color-mix function
 */
export const hexToRGBAString = (
  color: string,
  opacity: number | string
): string => {
  if (color.startsWith('var')) {
    // we're using CSS variables here
    return `color-mix(in srgb, ${color} calc(${opacity} * 100%), transparent)`
  }

  const rgbValues = hexToRGB(color)

  if (rgbValues.length === 3) {
    const [r, g, b] = rgbValues
    return `rgba(${r}, ${g}, ${b}, ${opacity})`
  }

  return ''
}
