import {
  ascend,
  descend,
  lensProp,
  map,
  over,
  pipe,
  prop,
  sort,
  sortWith
} from 'ramda'

import {Offer} from '../../../offer/types/offer'
import {Room} from '../../types/room'
import {getNightlyDisplayTotalPrice} from '../transformSapiRoomsResponse/utils/price'

const firstOfferSelector = (room: Room) => room.offers[0] || null

const getOfferNightlyDisplayTotal = (offer: Offer) => {
  const displayTotalPrice = getNightlyDisplayTotalPrice(offer.prices || [])

  // TODO (@Core): displayTotalPrice could technically be null according to types
  return Math.round(displayTotalPrice as number)
}

const getFirstOfferNightlyDisplayTotal = ascend(
  pipe(firstOfferSelector, getOfferNightlyDisplayTotal)
)

const sortOffers = sortWith<Offer>([
  ascend(getOfferNightlyDisplayTotal),
  descend(prop('canPayLater'))
])

const lensOffers = lensProp<Room, 'offers'>('offers')
const sortRoomOffers = (room: Room) => over(lensOffers, sortOffers, room)

/**
 * Sort rooms by lowest prices and canPayLater (secondary)
 */
export const sortRoomsByNightlyDisplayTotalAsc = (rooms: Room[]): Room[] => {
  return pipe(
    map(sortRoomOffers), // rooms -> rooms
    sort(getFirstOfferNightlyDisplayTotal) // rooms -> number
  )(rooms)
}
