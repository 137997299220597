import React from 'react'
import {useSelector} from 'react-redux'
import styled from '@emotion/styled'

import {CompactHeader} from '@daedalus/atlas/CompactHeader'
import {MOBILE_HEADER_HEIGHT} from '@daedalus/atlas/CompactHeader'
import {ANIMATION_TYPES, Overlay} from '@daedalus/atlas/Overlay'
import {cssTheme} from '@daedalus/atlas/themes'
import {
  selectIsAuthenticated,
  selectIsLoading
} from '@daedalus/core/src/auth/modules/selectors'
import {defaultBrand} from '@daedalus/core/src/brand/config'
import {Brand} from '@daedalus/core/src/brand/types'
import {FormattedMessageWrapper} from '@daedalus/core/src/localization/components/FormattedMessage'

import {MembershipContent} from './MembershipContent'

type PropsType = {
  onClose: () => void
  isOpen: boolean
  brand: Brand
  anonymousId: string
  onExploreButtonClick?: () => void
}

const HeaderWrapper = styled.div`
  position: relative;
  z-index: 2;
  border-bottom: 1px solid ${cssTheme.colors.border.neutral.c100};
`

const HeaderContentWrapper = styled.div`
  font-family: ${cssTheme.fonts.family.default};
  font-weight: ${cssTheme.fonts.weight.lg};
  line-height: ${cssTheme.fonts.lineHeight.xs};
  font-size: ${cssTheme.fonts.size.md};
  color: ${cssTheme.colors.content.neutral.c950};
`

const OverlaySection = styled.section`
  height: calc(100% - ${MOBILE_HEADER_HEIGHT}px);
  display: flex;
  flex-direction: column;
`

export const MembershipOverlay = ({
  isOpen,
  onClose,
  brand = defaultBrand,
  anonymousId,
  onExploreButtonClick
}: PropsType) => {
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const isLoading = useSelector(selectIsLoading)

  return (
    <Overlay
      isOpen={isOpen}
      animationType={ANIMATION_TYPES.SLIDE_RIGHT}
      shadeBackground={false}
      onClose={onClose}
    >
      <HeaderWrapper>
        <CompactHeader
          brand={brand}
          onBackButtonClick={onClose}
          centerContent={
            <HeaderContentWrapper>
              <FormattedMessageWrapper
                id="membership.membershipTitle"
                defaultMessage="Membership"
              />
            </HeaderContentWrapper>
          }
        />
      </HeaderWrapper>
      <OverlaySection>
        <MembershipContent
          isMobile
          isOverlay
          isAuthenticated={isAuthenticated}
          isLoading={isLoading}
          anonymousId={anonymousId}
          brand={brand}
          onExploreButtonClick={onExploreButtonClick}
        />
      </OverlaySection>
    </Overlay>
  )
}
