import React from 'react'
import {cx} from '@linaria/core'
import {styled} from '@linaria/react'

import {GemsText} from '@findhotel/atlas-assets'

import {cssTheme} from '../../../../themes'

interface Props {
  hasBorder?: boolean
}

const TextBackground = styled('div')`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
  border-radius: ${cssTheme.layout.radius.sm};
  border: 2px solid ${cssTheme.colors.border.neutral.c000};
  background-color: ${cssTheme.colors.background.special.c600};
  &.hasBorder {
    border: 2px solid ${cssTheme.colors.border.neutral.c000};
  }
`

export const GemsTextTag = ({hasBorder = false}: Props) => (
  <TextBackground className={cx(hasBorder && 'hasBorder')}>
    <GemsText size={6} />
  </TextBackground>
)
