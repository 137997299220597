import {isMobileApp} from '../../native'
import {getFriendAndFamilyCugType} from './getFriendAndFamilyCugType'
import {getPlusCugType, TokenFromUrlParams} from './getPlusCugType'
import {
  APP_META_CUG_TYPE,
  APP_META_SIGNED_IN_CUG_TYPE,
  APP_WEB_CUG_TYPE,
  MOBILE_APP_CUG_TYPE,
  OFFLINE_CUG_TYPE,
  PLUS_CUG_TYPE,
  SENSITIVE_CUG_TYPE,
  SIGNED_IN_CUG_TYPE,
  WEB_2_APP_CUG_TYPE
} from './privateDeals'

interface CugDealsParameters {
  isMemberPlus: boolean
  isEmployee: boolean
  urlParams: TokenFromUrlParams
  isBrandOffersLockedByDefault?: boolean
  isAppLockedDealAudience?: boolean
}

export const getCugDealsParameter = ({
  isMemberPlus,
  isEmployee,
  urlParams,
  isBrandOffersLockedByDefault = true,
  isAppLockedDealAudience = false
}: CugDealsParameters) => {
  // Force CUG deals when the link is from PriceWatch campaigns
  // This is a test on price-tracker accuracy and should be removed after conclusion
  if ('priceWatchId' in urlParams)
    return [
      SIGNED_IN_CUG_TYPE,
      OFFLINE_CUG_TYPE,
      SENSITIVE_CUG_TYPE,
      PLUS_CUG_TYPE,
      ...getFriendAndFamilyCugType(isEmployee)
    ]

  // https://app.shortcut.com/findhotel/story/119964/create-audience-and-fe-changes-for-high-yield-mor-environment
  return [
    SIGNED_IN_CUG_TYPE,
    OFFLINE_CUG_TYPE,
    SENSITIVE_CUG_TYPE,
    ...(isMobileApp() ? [MOBILE_APP_CUG_TYPE] : []),
    ...(!isMobileApp()
      ? [APP_META_CUG_TYPE, APP_META_SIGNED_IN_CUG_TYPE, APP_WEB_CUG_TYPE]
      : []),
    ...getPlusCugType(isMemberPlus, urlParams, isBrandOffersLockedByDefault),
    ...getFriendAndFamilyCugType(isEmployee),
    ...(isAppLockedDealAudience ? [WEB_2_APP_CUG_TYPE] : [])
  ]
}
