import Settings from 'Settings'

import {MY_BOOKINGS_PATH} from '@daedalus/core/src/booking/constants'
import {Brand} from '@daedalus/core/src/brand/types'
import {getMyBookingsHost, getSearchHost} from '@daedalus/core/src/brand/utils'
import {
  ensureUrlCorrectSlashes,
  stringifyUrlObject
} from '@daedalus/core/src/utils/url'

import buildUrl from './buildUrl'

const SEARCH_RESULTS_PATH = '/Hotel/Search'

// Ensures that public url always has a trailling slash
export const publicUrl = ensureUrlCorrectSlashes([Settings.get('PUBLIC_URL')])

export const getSearchResultsUrl = (brand: Brand) => {
  try {
    const host = getSearchHost({
      brand,
      appEnv: Settings.get('REACT_APP_ENV')
    })

    return new URL(SEARCH_RESULTS_PATH, host).href
  } catch {
    return ''
  }
}

export const getMyBookingsUrl = (
  brand: Brand,
  searchParams?: Record<string, unknown>
) => {
  try {
    const host = getMyBookingsHost({
      brand,
      appEnv: Settings.get('REACT_APP_ENV')
    })

    const address = new URL(MY_BOOKINGS_PATH, host).href

    return buildUrl(address, searchParams)
  } catch {
    return ''
  }
}

export const getAccommodationUrlPersistingParams = (
  brand: Brand,
  search: string
) => {
  const urlParams = new URLSearchParams(search)

  const hotelId = urlParams.get('hotelId')
  const checkIn = urlParams.get('checkIn')
  const checkOut = urlParams.get('checkOut')

  if (hotelId && checkIn && checkOut)
    return new URL(
      `/Hotel/${hotelId}?${stringifyUrlObject({checkIn, checkOut})}`,
      getSearchResultsUrl(brand)
    ).href
  return null
}
