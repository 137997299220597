import React, {useMemo} from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'

import ContentWrapper from '@daedalus/atlas/helpers/ContentWrapper'
import {Stack} from '@daedalus/atlas/helpers/Stack'
import {Text} from '@daedalus/atlas/Text'
import {cssTheme} from '@daedalus/atlas/themes'
import {mq} from '@daedalus/atlas/utils/breakpoints'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'

import {SelectorListItem} from './SelectorListItem'

interface Props<T> {
  value: string
  isMobile?: boolean
  onChange: (code: string) => void
  items: T[]
  splitPopularAndOther: (items: T[]) => {itemsList: T[]; popularItemsList: T[]}
  popularItemsTitle: string
  otherItemsTitle: string
  isSearching?: boolean
  getValue: (item: T) => string
  renderItem: (item: T, isMobile: boolean) => React.ReactNode
}

const ListWrapper = styled.ul<{isMobile: boolean}>(
  ({isMobile}) => css`
    padding: 0;
    margin: 0;
    ${!isMobile &&
    css`
      display: grid;
      column-gap: ${cssTheme.layout.spacing.s500};
      row-gap: ${cssTheme.layout.spacing.s300};
      margin-top: ${cssTheme.layout.spacing.s300};
      ${mq.desktopXs(css`
        & {
          grid-template-columns: repeat(2, 320px);
        }
      `)}
      ${mq.desktopSm(css`
        & {
          grid-template-columns: repeat(3, 320px);
        }
      `)}
      ${mq.desktopMd(css`
        & {
          grid-template-columns: repeat(4, 216px);
        }
      `)}
    `}
  `
)

const NoResultsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: ${cssTheme.layout.spacing.s900} 0;
  text-align: center;
`
export const SelectorList = <T,>({
  items,
  value,
  onChange,
  isMobile = false,
  splitPopularAndOther,
  popularItemsTitle,
  otherItemsTitle,
  getValue,
  renderItem,
  isSearching
}: Props<T>) => {
  const {itemsList, popularItemsList} = useMemo(
    () => splitPopularAndOther(items),
    [splitPopularAndOther, items]
  )

  const hasPopularItems = popularItemsList.length > 0
  const hasOtherItems = itemsList.length > 0

  const renderListItem = (item: T) => {
    const itemValue = getValue(item)

    return (
      <SelectorListItem
        key={itemValue}
        item={item}
        isMobile={isMobile}
        isActive={itemValue === value}
        onClick={onChange}
        getValue={getValue}
        renderItem={renderItem}
      />
    )
  }

  if (isSearching && isMobile) {
    if (hasPopularItems || hasOtherItems)
      return (
        <ContentWrapper paddingX="s200">
          <Stack space="s500">
            <ListWrapper isMobile>
              {[...popularItemsList, ...itemsList].map(renderListItem)}
            </ListWrapper>
          </Stack>
        </ContentWrapper>
      )

    return (
      <ContentWrapper paddingX="s200">
        <NoResultsWrapper>
          <Text variant="titleL">
            <FormattedMessageWrapper
              id="selectorList.noResultsTitle"
              defaultMessage="No results found"
            />
          </Text>
          <ListWrapper isMobile>
            <Text variant="bodyM">
              <FormattedMessageWrapper
                id="selectorList.noResultsDescription"
                defaultMessage="We couldn't find any matches for your search."
              />
            </Text>
          </ListWrapper>
        </NoResultsWrapper>
      </ContentWrapper>
    )
  }

  return (
    <ContentWrapper paddingX="s200">
      <Stack space="s500">
        {hasPopularItems && (
          <div>
            <Text variant={isMobile ? 'titleM' : 'titleL'}>
              {popularItemsTitle}
            </Text>
            <ListWrapper isMobile={isMobile}>
              {popularItemsList.map(renderListItem)}
            </ListWrapper>
          </div>
        )}
        {hasOtherItems && (
          <div>
            <Text variant={isMobile ? 'titleM' : 'titleL'}>
              {otherItemsTitle}
            </Text>
            <ListWrapper isMobile={isMobile}>
              {itemsList.map(renderListItem)}
            </ListWrapper>
          </div>
        )}
      </Stack>
    </ContentWrapper>
  )
}
