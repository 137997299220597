import React from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'

import {Card} from '@daedalus/atlas/Card'
import {PageWidthContainer as PageWidthContainerElement} from '@daedalus/atlas/helpers/PageWidthContainer'
import {cssTheme} from '@daedalus/atlas/themes'
import {mq} from '@daedalus/atlas/utils/breakpoints'

import {PropsType} from './types'
import {WhatIsGems} from './WhatIsGems/WhatIsGems'

const SIDEBAR_MAX_WIDTH = 294
const FOOTER_HEIGHT = 65
const HEADER_DESKTOP_HEIGHT = 89
const HEADER_MOBILE_HEIGHT = 65

const PageWidthContainer = styled(PageWidthContainerElement)`
  height: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${mq.desktopXs(css`
    padding: 0 ${cssTheme.layout.spacing.s500};
  `)}
`

const PageContainerWrapper = styled.div`
  min-height: calc(100vh - ${HEADER_MOBILE_HEIGHT}px);
  ${mq.desktopXs(css`
    min-height: calc(100vh - ${HEADER_DESKTOP_HEIGHT}px - ${FOOTER_HEIGHT}px);
    padding: ${cssTheme.layout.spacing.s500} 0;
    background: ${cssTheme.colors.background.neutral.c050};
  `)}
`

const PageGrid = styled.div`
  margin-bottom: ${cssTheme.layout.spacing.s600};
  margin-top: -3px;

  ${mq.desktopXs(css`
    display: grid;
    grid-template-columns:
      ${SIDEBAR_MAX_WIDTH}px
      minmax(min-content, auto);
    gap: ${cssTheme.layout.spacing.s600};
    margin-bottom: 0;
    margin-top: 0;
  `)}
`

const SideBarWrapper = styled(Card)`
  height: fit-content;
  overflow: auto;
`

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${mq.desktopXs(css`
    gap: ${cssTheme.layout.spacing.s500};
  `)}
`

export const MembershipContent = ({
  isMobile,
  isAuthenticated,
  anonymousId,
  profilePicture,
  accountSideMenu,
  brand
}: PropsType) => {
  const DesktopSideMenu = () =>
    !isMobile ? (
      <div>
        <SideBarWrapper borderRadius="md" shadow="action">
          {isAuthenticated && profilePicture}
          {!!accountSideMenu && accountSideMenu}
        </SideBarWrapper>
      </div>
    ) : null

  return (
    <PageContainerWrapper>
      <PageWidthContainer>
        <PageGrid>
          <DesktopSideMenu />
          <MainWrapper>
            <WhatIsGems brand={brand} anonymousId={anonymousId} />
          </MainWrapper>
        </PageGrid>
      </PageWidthContainer>
    </PageContainerWrapper>
  )
}
