import {getDomainName} from '../../../utils/url'
import {cookieSecuritySettings} from '../../utils/cookies/cookieSecuritySettings'

const maxAge = 30 // 30 days
const name = 'sapiLabel'

export const sapiLabelCookieConfig = {
  name,
  options: {
    maxAge,
    domain: getDomainName(window.location.hostname),
    ...cookieSecuritySettings(true)
  }
}
