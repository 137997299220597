import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import styled from '@emotion/styled'

import {useToastController} from '@daedalus/atlas/context/toastController'
import {Spinner} from '@daedalus/atlas/Spinner'
import {Text} from '@daedalus/atlas/Text'
import {cssTheme} from '@daedalus/atlas/themes'
import {getIsBrandVio} from '@daedalus/core/src/_web/brand/modules/selectors'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  AnalyticsContext,
  Category,
  Entity,
  Team
} from '@daedalus/core/src/analytics/types/Events'
import {updateUser} from '@daedalus/core/src/auth/components/FetchUser'
import {getUser} from '@daedalus/core/src/auth/modules/selectors'
import {redirectUsersFromCallbackUrl} from '@daedalus/core/src/auth/modules/thunks'
import {Hub} from '@daedalus/core/src/auth/services'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'

import vioGif from './vio.gif'

const Wrapper = styled.div`
  background-color: ${cssTheme.colors.background.neutral.c000};
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

const LoaderBox = styled.div`
  display: flex;
  gap: ${cssTheme.layout.spacing.s400};
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const VioLoader = () => (
  <LoaderBox>
    <img src={vioGif} width="150" alt="vio-logo-gif" />
    <Text variant="titleL">
      <FormattedMessageWrapper
        id="cognitoPasswordlessLoader.text"
        defaultMessage="Signing in..."
      />
    </Text>
  </LoaderBox>
)

export const CognitoPasswordlessLoader = () => {
  const dispatch = useDispatch()
  const user = useSelector(getUser)
  const history = useHistory()
  const toast = useToastController()
  const isVio = useSelector(getIsBrandVio)

  useEffect(() => {
    const unsubscribe = Hub.listen('auth', ({payload}) => {
      switch (payload.event) {
        case 'signInWithRedirect':
          updateUser({dispatch})
          break
        case 'signInWithRedirect_failure':
          console.error('signInWithRedirect_failure')
          break
      }
    })

    return unsubscribe
  }, [dispatch])

  // Cognito authentication handler: redirect the user after the user is authenticated
  useEffect(() => {
    const redirectUserToPreviousUrl = () => {
      if (user) {
        dispatch(redirectUsersFromCallbackUrl({history, user}))
      }
    }

    const timer = setTimeout(() => {
      toast.open(
        <FormattedMessageWrapper
          id="crashPage.message.title"
          defaultMessage="Uh oh, something went wrong"
        />
      )
      redirectUserToPreviousUrl()
      dispatch(
        trackEvent({
          category: Category.System,
          entity: Entity.Authentication,
          action: Action.Errored,
          team: Team.Retention,
          analyticsContext: {
            [AnalyticsContext.ErrorContext]: {
              errorType: 'authentication_callback_stuck',
              errorMessage:
                'The user seems to get stuck on the authentication callback page'
            }
          }
        })
      )
    }, 10000)

    if (user) {
      clearTimeout(timer)
      redirectUserToPreviousUrl()
    }

    return () => {
      clearTimeout(timer)
    }
  }, [user, dispatch, history])

  return <Wrapper>{isVio ? <VioLoader /> : <Spinner size={100} />}</Wrapper>
}
