import {
  safelyGetStorageItem,
  safelySetStorageItem
} from '../../../_web/utils/persistence'
import {AuthenticationFlows} from '../../types/Auth'

export const AUTHENTICATION_FLOW_STORAGE_KEY = 'authenticationFlow'

export const storeAuthenticationFlow = (
  flow: AuthenticationFlows | null
): void => {
  if (!flow) return
  safelySetStorageItem(sessionStorage, AUTHENTICATION_FLOW_STORAGE_KEY, flow)
}

export const getAuthenticationFlow = (): AuthenticationFlows | undefined =>
  safelyGetStorageItem(
    sessionStorage,
    AUTHENTICATION_FLOW_STORAGE_KEY,
    undefined
  ) as AuthenticationFlows | undefined
