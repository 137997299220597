import React, {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {css, Global} from '@emotion/react'
import styled from '@emotion/styled'
import ToasterMessage from 'components/presentational/ToasterMessage'
import {useNetworkStatus} from 'hooks/useNetworkStatus'
import {ThemeType} from 'types/Theme'

import {Spinner} from '@daedalus/atlas/Spinner'
import {Text} from '@daedalus/atlas/Text'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  Category,
  Entity
} from '@daedalus/core/src/analytics/types/Events'
import {FormattedMessageWrapper} from '@daedalus/core/src/localization/components/FormattedMessage'

const SpinnerWrapper = styled('div')(
  ({theme}: {theme: ThemeType}) => css`
    margin: 0 ${theme.layout.spacing.s300}px 0 ${theme.layout.spacing.s500}px;
  `
)
const OfflineComponent = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      trackEvent({
        category: Category.User,
        entity: Entity.OfflineStatusMessage,
        action: Action.Viewed,
        payload: {
          navigatorOnline: navigator.onLine
        }
      })
    )
  }, [dispatch])
  return (
    <>
      <Global
        styles={css`
          body {
            overflow: hidden;
          }
        `}
      />
      <ToasterMessage>
        <Text>
          <FormattedMessageWrapper
            id="offlineMessage"
            defaultMessage="No internet connection. We are trying to reconnect…"
          />
        </Text>
        <SpinnerWrapper>
          <Spinner size={24} colorPath="content.neutral.c000" />
        </SpinnerWrapper>
      </ToasterMessage>
    </>
  )
}

const NetworkMonitor = () => {
  const isOnline = useNetworkStatus()
  if (isOnline) return null
  return <OfflineComponent />
}

export default NetworkMonitor
