import {useCallback} from 'react'
import {useDispatch} from 'react-redux'
import {AnyAction} from '@reduxjs/toolkit'

import {useTrackingTeam} from './useTrackingTeam'

/**
 * Gets the team related to the feature from TeamContext and patches an event action with the `team` property
 * This property is used to send events from integrated features to different analytics endpoints from any app.
 */
export const useDispatchTeamEvent = () => {
  const team = useTrackingTeam()
  const dispatch = useDispatch()

  const dispatchTeamEvent = useCallback(
    <A extends AnyAction>(action: A): A =>
      dispatch({
        ...action,
        payload: {
          ...action.payload,
          team
        }
      }),
    [dispatch, team]
  )

  return dispatchTeamEvent
}
