import React from 'react'
import {useSelector} from 'react-redux'
import useParams from 'components/data/URLParams/useParams'
import {getAnonymousId} from 'modules/meta/selectors'
import {mergeDeepLeft} from 'ramda'
import utmSources from 'utils/utmSources'

import {useBrand} from '@daedalus/core/src/_web/brand/hooks/useBrand'
import {useAddFeatureFlagTracking} from '@daedalus/core/src/analytics/hooks/useAddFeatureFlagTracking'
import {
  ExperimentPackageName,
  useTrackServerABTestSource
} from '@daedalus/core/src/analytics/hooks/useTrackServerABTestSource'
import {getLastLoginUrl} from '@daedalus/core/src/auth/business/persistData'
import {
  selectHasVioEmail,
  selectIsAuthenticated,
  selectIsEmployee,
  selectIsMemberPlus
} from '@daedalus/core/src/auth/modules/selectors'
import {useOpticks} from '@daedalus/core/src/experiments/components/OpticksProvider'
import {useOpticksAudienceSegmentation} from '@daedalus/core/src/experiments/hooks/useOpticksAudienceSegmentation'
import {
  getMobileAppVersion,
  isReactNativeWebView
} from '@daedalus/core/src/native'
import {parseQueryString, parseUrl} from '@daedalus/core/src/utils/url'
import {UrlCheckoutParamsType} from '@daedalus/core/src/utils/url/types/UrlParams'
import {getDevicePlatform} from '@daedalus/core/src/utils/userAgent'
import {useDebugPanelToggles} from '@daedalus/debug-panel'

import type {AudienceSegmentationAttributes} from '@daedalus/core/src/experiments/types'

// Fallback params must be provided during the login
// This url is persisted each login in the localStorage
const getFallbackParams = () => {
  const lastLoginUrl = getLastLoginUrl()

  if (lastLoginUrl) {
    const fallbackUrl = parseUrl(lastLoginUrl)
    return parseQueryString(fallbackUrl?.search)
  }

  return {}
}

const InitializeABTesting = ({
  children
}: React.PropsWithChildren<Record<string, unknown>>): JSX.Element => {
  const Opticks = useOpticks()
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const isEmployee = useSelector(selectIsEmployee)
  const hasVioEmail = useSelector(selectHasVioEmail)
  const isMemberPlus = useSelector(selectIsMemberPlus)
  const {brand} = useBrand()
  const urlParams = mergeDeepLeft(useParams(), getFallbackParams())
  const anonymousId = useSelector(getAnonymousId)
  const {experiments} = urlParams

  const collectSegmentationAttributes = (): AudienceSegmentationAttributes => {
    const {
      deviceType: deviceCategory = 'desktop',
      utm_source,
      locale = 'en',
      userCountry = 'US',
      providerCode = '',
      isMetaTraffic = '',
      profile = ''
    } = urlParams as UrlCheckoutParamsType
    const trafficSource = utmSources(utm_source)
    const devicePlatform = getDevicePlatform()

    return {
      application: 'checkout',
      isMetaTraffic,
      deviceCategory,
      devicePlatform,
      trafficSource,
      locale,
      userCountry,
      providerCode,
      brandCode: brand.code,
      profile
    }
  }

  const {applyLocalstorageToggles} = useDebugPanelToggles({
    params: location.search
  })

  const opticksAttributes = collectSegmentationAttributes()
  const isWebView = isReactNativeWebView()
  const mobileAppVersion = getMobileAppVersion()

  const audienceSegmentationAttributes: AudienceSegmentationAttributes = {
    ...opticksAttributes,
    hasVioEmail,
    isAuthenticated,
    isMemberPlus,
    isEmployee,
    isWebView,
    mobileAppVersion
  }

  useOpticksAudienceSegmentation(audienceSegmentationAttributes)

  useTrackServerABTestSource(ExperimentPackageName.Checkout)
  useAddFeatureFlagTracking()

  if (anonymousId && Opticks) {
    Opticks.setUserId(anonymousId)
    Opticks.toggle('a-a-experiment')
    Opticks.setAudienceSegmentationAttributes(audienceSegmentationAttributes)

    //wrap toggles from other sources with localstorage toggles
    const allForcedToggles = applyLocalstorageToggles({
      ...experiments
    })

    if (allForcedToggles) {
      Opticks.forceToggles(allForcedToggles)
    }

    // Extra test to verify AB testing infrastructure is working as intended between Search / Checkout
    Opticks.toggle('a-a-experiment-v2')

    return children as JSX.Element
  }

  /**
   * Edge case for the Auth0 login callback page which doesn't have anonymousId.
   * We still want to render that page, since after the redirect takes place we have the anonymousId
   * then we will render experiments as we'd normally would.
   *
   * When we do have an anonymousId it means OpticksProvider isn't loaded yet, we wait with rendering
   * as it would otherwise render the a side of all experiments before OpticksProvider is available.
   */
  return (anonymousId ? null : children) as JSX.Element
}

export default InitializeABTesting
