// TODO (Core): This import will be fixed when utils/url is migrated
import {generateEncodedSapiLabel} from '../../../sapiLabel/business'
import {parseQueryString} from '../../../utils/url'
import {getAnonymousId} from '../../anonymousId'
import {getPersistedSapiLabel} from '.'

/**
 * Get an encoded sapiLabel from browser context (landing query string, cookies)
 */
export const generateSapiLabelFromBrowserContext = (
  landingQueryString: string,
  partnerId: string | undefined
) => {
  const anonymousId = getAnonymousId()
  const hasPersistedSapiLabel = !!getPersistedSapiLabel()
  const landingParameters = parseQueryString(landingQueryString) as Record<
    string,
    string
  >

  if (!anonymousId) return // type guard

  return generateEncodedSapiLabel({
    landingParameters,
    anonymousId,
    partnerId,
    hasPersistedSapiLabel
  })
}
