import {getBovioApiKey} from '../../../booking/utils'
import {BrandCode} from '../../../brand/types'
import {useGetBoVioConfiguration} from './useGetBoVioConfiguration'

interface Props {
  boVioUrl: string
  brandCode: BrandCode
  appEnv: string
  urlParams: Record<string, unknown>
}

/**
 * This component triggers request to BoVio API offers/configurations endpoint.
 * Received configuration is stored in session storage and needed to check offers eligibility for deal freeze and charge later.
 * After that configuration become accessible via useGetBoVioConfiguration hook.
 */

export const GetBoVioConfiguration = ({
  boVioUrl,
  brandCode,
  appEnv,
  urlParams
}: Props) => {
  const boVioProfile = getBovioApiKey({brandCode, appEnv, urlParams})
  useGetBoVioConfiguration({boVioUrl, boVioProfile})

  return null
}
