// TODO (Core): Move this type to a package
import {CookieConfig} from 'types/Cookie'

import {getCookie, setCookie} from '../../utils/cookies'
import {sapiLabelCookieConfig} from './cookieConfig'
import {generateSapiLabelFromBrowserContext} from './generateBrowserSapiLabel'

export {generateSapiLabelFromBrowserContext} from './generateBrowserSapiLabel'

const getCookieOptions = (cookieConfig: CookieConfig) => {
  const {secure, sameSite, maxAge: expires, domain} = cookieConfig.options
  return {
    expires,
    secure,
    sameSite,
    domain
  }
}

export const getPersistedSapiLabel = () => getCookie(sapiLabelCookieConfig.name)

/**
 * This function returns existing SAPI label or generates and persists a new one using current location url.
 * It can be used in cases of navigation to non-search web app pages from external (white labeled) websites.
 *
 * @param partnerId - Optional parameters for label generation
 * @returns string - SAPI label
 */
export const getPersistedSapiLabelWithFallback = (partnerId?: string) => {
  const existingLabel = getPersistedSapiLabel()

  if (existingLabel) {
    return existingLabel
  }

  const fallbackLabel =
    generateSapiLabelFromBrowserContext(
      window?.location?.search || '',
      partnerId
    ) || ''

  persistSapiLabel(fallbackLabel)

  return fallbackLabel
}

export const persistSapiLabel = (sapiLabel: string) =>
  setCookie(
    sapiLabelCookieConfig.name,
    sapiLabel,
    getCookieOptions(sapiLabelCookieConfig)
  )
