import styled from '@emotion/styled'

import {cssTheme} from '@daedalus/atlas/themes'

export const CustomInputSelect = styled.select`
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  ${cssTheme.typography.text.labelM};
`

export const WrapperSelector = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`

export const RowWrapper = styled.div`
  padding: ${cssTheme.layout.spacing.s500} 0;
  border-bottom: solid 1px ${cssTheme.colors.border.neutral.c100};
`

export const Link = styled.a`
  text-decoration: none;
  color: ${cssTheme.colors.content.neutral.c950};

  &:hover,
  &:visited,
  &:focus {
    text-decoration: none;
    color: ${cssTheme.colors.content.neutral.c950};
  }
`

export const IconWrapper = styled.div`
  color: ${cssTheme.colors.content.neutral.c600};
  /* @mirror */
`

export const Label = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${IconWrapper} {
    margin-right: ${cssTheme.layout.spacing.s300};
  }
`
