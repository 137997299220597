import React from 'react'
import {useSelector} from 'react-redux'
import styled from '@emotion/styled'
import {toggle} from 'opticks'

import {Avatar, AvatarType} from '@daedalus/atlas/Avatar'
import {Icon} from '@daedalus/atlas/Icon'
import {Text} from '@daedalus/atlas/Text'
import {cssTheme} from '@daedalus/atlas/themes'
import {useBrand} from '@daedalus/core/src/_web/brand/hooks/useBrand'
import {getUserName} from '@daedalus/core/src/auth/business/authentication'
import {getUser} from '@daedalus/core/src/auth/modules/selectors'
import {FormattedMessageWrapper} from '@daedalus/core/src/localization/components/FormattedMessage'

import {PrivateDealTag} from '../../../privateDeal/PrivateDealTag'

const IconWrapper = styled.div`
  height: 100%;

  & svg {
    /* @mirror */
  }
`

const RowLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;

  :hover,
  :visited {
    text-decoration: none;
    color: inherit;
  }
`

const UserInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`

const HeaderTag = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0;
  margin-left: ${cssTheme.layout.spacing.s500};
  gap: ${cssTheme.layout.spacing.s300};
`

const SecondaryText = styled(Text)`
  color: ${cssTheme.colors.content.neutral.c600};
`

interface MobileMenuUserInfoPropTypes {
  onShowAccountOverlay: () => void
}

export const MobileMenuUserInfo = ({
  onShowAccountOverlay
}: MobileMenuUserInfoPropTypes) => {
  const {brandCode, brandIsVio, brandPrivateDealMessage} = useBrand()
  const user = useSelector(getUser)
  const userGivenName = user?.attributes?.given_name
  const userFamilyName = user?.attributes?.family_name
  const userName = userGivenName || userFamilyName

  return (
    <RowLink onClick={onShowAccountOverlay}>
      <UserInfo>
        <Avatar
          picture={user?.attributes?.picture}
          name={getUserName(user)}
          size="lg"
          type={
            user?.attributes?.picture ? AvatarType.Picture : AvatarType.Icon
          }
          borderColorPath="border.special.c300"
        />

        <HeaderTag>
          <div>
            <Text variant="titleL" className="fs-exclude">
              {userName ? (
                <FormattedMessageWrapper
                  id="userWidget.greeting"
                  defaultMessage="Hi, {user}"
                  values={{
                    user: userName
                  }}
                />
              ) : (
                <>
                  <FormattedMessageWrapper
                    id="userWidget.enterYourName"
                    defaultMessage="Enter your name"
                  />
                  <Text variant="titleL">{userGivenName}</Text>
                </>
              )}
            </Text>
            {user?.attributes?.email ? (
              <SecondaryText className="fs-exclude" variant="bodyS">
                {user?.attributes?.email}
              </SecondaryText>
            ) : null}
          </div>
          {brandIsVio && (
            <PrivateDealTag
              brandCode={brandCode}
              message={toggle(
                'c9f92dea-new-tags-poc',
                brandPrivateDealMessage,
                {
                  id: 'vioDeals.member',
                  defaultMessage: 'Vio Member'
                }
              )}
            />
          )}
        </HeaderTag>
      </UserInfo>
      <IconWrapper>
        <Icon name="Edit" />
      </IconWrapper>
    </RowLink>
  )
}
