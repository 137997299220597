import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {changePreferencesVisibility} from 'modules/overlay/actions'

import {CompactHeader} from '@daedalus/atlas/CompactHeader'
import {useBrand} from '@daedalus/core/src/_web/brand/hooks/useBrand'
import {Page} from '@daedalus/core/src/analytics/types/Events'
import {
  getUser,
  selectAuthEnabled,
  selectIsAuthenticated
} from '@daedalus/core/src/auth/modules/selectors'
import {isReactNativeWebView} from '@daedalus/core/src/native'

import {MobileHeaderMenu} from './MobileHeaderMenu'

interface Props {
  /** Override the default center content (defaults to search form if enabled) */
  centerContent?: React.ReactElement
  hasShadow?: boolean
  hasHeaderMenu?: boolean
  disableLogoClick?: boolean
  onBackButtonClick?: () => void
  pageName?: Page
  showStartLogo?: boolean
}

export const MobileHeader = ({
  hasHeaderMenu = true,
  hasShadow = false,
  disableLogoClick = false,
  centerContent,
  onBackButtonClick,
  showStartLogo,
  pageName
}: Props) => {
  const isPaymentPage = pageName === Page.Payment
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const dispatch = useDispatch()
  const {brand} = useBrand()
  const user = useSelector(getUser)
  const authEnabled = useSelector(selectAuthEnabled)
  const showMenuOnRNWebView = !isReactNativeWebView()

  const shouldShowEndContent = hasHeaderMenu && showMenuOnRNWebView
  const hasExpandedCenterContent = !showMenuOnRNWebView && !isPaymentPage

  const handleShowPreferences = () => {
    dispatch(changePreferencesVisibility(true, false, false)())
  }

  const EndContent = () => {
    if (
      (shouldShowEndContent && isPaymentPage && isAuthenticated) ||
      (shouldShowEndContent && !isPaymentPage)
    ) {
      return (
        <MobileHeaderMenu
          shouldShowUserAvatar={authEnabled}
          user={user || undefined}
          onShowPreferences={handleShowPreferences}
          hideBurgerMenu={isPaymentPage}
        />
      )
    }
    return null
  }

  return (
    <CompactHeader
      brand={brand}
      hasExpandedCenterContent={hasExpandedCenterContent}
      hasShadow={hasShadow}
      logoUrl={brand.address}
      onBackButtonClick={onBackButtonClick}
      disableLogoClick={disableLogoClick}
      showCenterLogo={!centerContent}
      showStartLogo={showStartLogo}
      centerContent={centerContent ? centerContent : undefined}
      endContent={<EndContent />}
    />
  )
}
