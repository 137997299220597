import React, {ReactNode} from 'react'
import styled from '@emotion/styled'

import {Button} from '@daedalus/atlas/Button'
import {ContentWrapper} from '@daedalus/atlas/helpers/ContentWrapper'
import {Grid} from '@daedalus/atlas/helpers/Grid'
import {Icon} from '@daedalus/atlas/Icon'
import {Link} from '@daedalus/atlas/Link'
import {Text} from '@daedalus/atlas/Text'
import {cssTheme} from '@daedalus/atlas/themes'
import {FormattedMessageWrapper} from '@daedalus/core/src/localization/components/FormattedMessage'

export interface PhoneType {
  countryCode: string
  number: string
}

interface CustomerSupportDetailsProps {
  /** Property email address */
  propertyEmail?: string

  /** Property phone number object containing country code and phone number, without the leading zero */
  propertyPhone?: PhoneType

  /** Customer support email address */
  supportEmail?: string

  /** Customer support phone number string containing country code and phone number, without the leading zero */
  supportPhone?: string

  /** Boolean to determine whether we should show options like FB messenger and WhatsApp, should only be available for MOR bookings */
  hasInstantMessagingContacts: boolean

  /** Customer support phone number string containing country code and phone number, without the leading zero */
  whatsAppLink?: string

  /** Customer support link to a chat with our Facebook page */
  facebookMessengerLink?: string

  /** Mor bookings */
  isMor?: boolean
}

interface ContactRowProps {
  icon: ReactNode
  label: string | ReactNode
  contact: ReactNode
}

export const HeadingElement = styled(Text)`
  margin-bottom: ${cssTheme.layout.spacing.s400};
`

const ContactRowWrapper = styled('div')`
  padding: ${cssTheme.layout.spacing.s600} 0;

  &:last-child {
    padding-bottom: 0;
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${cssTheme.colors.border.neutral.c100};
  }
`

const IconWrapper = styled('div')`
  color: ${cssTheme.colors.content.neutral.c600};
`

const PhoneNumberWrapper = styled('span')`
  direction: ltr !important;
  display: inline-block;
`

const SafeLtrPhoneNumberDisplay: React.FC = ({children}) => {
  return (
    <>
      <PhoneNumberWrapper>&lrm;{children}</PhoneNumberWrapper>
    </>
  )
}

const ContactWrapper = styled('div')`
  font-size: ${cssTheme.fonts.size.md};
`

const SubtitleHeader = styled(Text)`
  margin-bottom: ${cssTheme.layout.spacing.s500};
`

const concatenatePhoneNumber = ({countryCode, number}: PhoneType) =>
  `${countryCode} ${number}`

const ContactRow = ({icon, label, contact}: ContactRowProps) => (
  <ContactRowWrapper>
    <Grid container alignItems="center" spacing="s300">
      <Grid mobileSm="fit">
        <IconWrapper>{icon}</IconWrapper>
      </Grid>
      <Grid mobileSm="fill">
        <Text variant="labelM">{label}</Text>
      </Grid>
      <Grid mobileSm="fit">
        <ContactWrapper>{contact}</ContactWrapper>
      </Grid>
    </Grid>
  </ContactRowWrapper>
)

const StyledButton = styled(Button)`
  &:not(:last-child) {
    margin-right: 80px;
  }

  > div {
    padding-left: 0;
    padding-right: 0;
  }
`

const MessengerContactElement = ({link}: {link?: string}) => (
  <StyledButton
    variant="quiet"
    iconStart={<Icon name="FacebookMessenger" />}
    onClick={() => window.open(link, '_blank')}
  >
    Messenger
  </StyledButton>
)

const WhatsAppContactElement = ({link}: {link?: string}) => (
  <StyledButton
    variant="quiet"
    iconStart={<Icon name="WhatsappColor" />}
    onClick={() => window.open(link, '_blank')}
  >
    Whatsapp
  </StyledButton>
)

const EmailContainer = styled('div')`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  gap: ${cssTheme.layout.spacing.s300};
  align-items: center;
  padding: ${cssTheme.layout.spacing.s600} 0 0 0;
`

export const CustomerSupportDetails = ({
  supportEmail,
  supportPhone,
  propertyEmail,
  propertyPhone,
  hasInstantMessagingContacts,
  whatsAppLink,
  facebookMessengerLink,
  isMor = false
}: CustomerSupportDetailsProps) => {
  const hasAnySupportInfo =
    supportEmail || supportPhone || propertyEmail || propertyPhone

  return (
    <>
      {hasAnySupportInfo && (
        <HeadingElement variant="titleL">
          <FormattedMessageWrapper
            id="confirmationSupport.title"
            defaultMessage="Questions?"
          />
        </HeadingElement>
      )}

      {supportPhone && (
        <ContactRow
          icon={<Icon name="PhoneCallIncoming" />}
          label={
            <FormattedMessageWrapper
              id="confirmationSupport.callUs"
              defaultMessage="Call us"
            />
          }
          contact={
            <Link href={`tel: ${supportPhone} `}>
              <SafeLtrPhoneNumberDisplay>
                {supportPhone}
              </SafeLtrPhoneNumberDisplay>
            </Link>
          }
        />
      )}

      {supportEmail && isMor && (
        <EmailContainer>
          <IconWrapper>
            <Icon name="Mail" />
          </IconWrapper>
          <Link href={supportEmail} target="_blank">
            <Text variant="labelM">
              <FormattedMessageWrapper
                id="confirmationSupport.emailUs"
                defaultMessage="Email us"
              />
            </Text>
          </Link>
        </EmailContainer>
      )}

      {supportEmail && !isMor && (
        <ContactRow
          icon={<Icon name="Mail" />}
          label={
            <FormattedMessageWrapper
              id="confirmationSupport.emailUs"
              defaultMessage="Email us"
            />
          }
          contact={<Link href={`mailto: ${supportEmail}`}>{supportEmail}</Link>}
        />
      )}

      {propertyPhone && (
        <ContactRow
          icon={<Icon name="PhoneCallIncoming" />}
          label={
            <FormattedMessageWrapper
              id="confirmationSupport.contactProperty"
              defaultMessage="Contact property"
            />
          }
          contact={
            <Link
              href={`tel:${concatenatePhoneNumber({
                countryCode: propertyPhone.countryCode,
                number: propertyPhone.number
              })} `}
            >
              <SafeLtrPhoneNumberDisplay>
                {concatenatePhoneNumber({
                  countryCode: propertyPhone.countryCode,
                  number: propertyPhone.number
                })}
              </SafeLtrPhoneNumberDisplay>
            </Link>
          }
        />
      )}

      {propertyEmail && (
        <ContactRow
          icon={<Icon name="Mail" />}
          label={
            <FormattedMessageWrapper
              id="confirmationSupport.emailProperty"
              defaultMessage="Email property"
            />
          }
          contact={
            <Link href={`mailto: ${propertyEmail}`}>{propertyEmail}</Link>
          }
        />
      )}

      {hasInstantMessagingContacts && (
        <ContentWrapper paddingTop="s700">
          <SubtitleHeader variant="bodyM">
            <FormattedMessageWrapper
              id="confirmationSupport.weAreAlsoAvailable"
              defaultMessage="We are also available on"
            />
          </SubtitleHeader>
          <Grid container spacing="s000">
            <MessengerContactElement link={facebookMessengerLink} />
            <WhatsAppContactElement link={whatsAppLink} />
          </Grid>
        </ContentWrapper>
      )}
    </>
  )
}
