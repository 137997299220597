import {includes, isEmpty, isNil} from 'ramda'

export enum RoundingStrategyVariants {
  round = 'round',
  trunc = 'trunc',
  floor = 'floor'
}
// TODO [>=1.0.0]: Use enum
export type RoundingStrategy = keyof typeof RoundingStrategyVariants

export const getRoundingStrategy = () => RoundingStrategyVariants.round

export type RoundingStrategyParam = RoundingStrategy | 'none'

/**
 * Rounds a value based on the chosen strategy.
 *
 * @param strategy - rounding strategy to apply to formatted value
 * @param value - number to round
 *
 * @returns rounded number
 */
export const applyRoundingStrategy = (
  strategy: RoundingStrategyParam | undefined = 'none',
  value: number
): number =>
  includes(strategy, ['round', 'trunc', 'floor'])
    ? Math[strategy as RoundingStrategy](value)
    : value

/**
 * Formats the number for optimal reading. If the number is an integer, keep it as is,
 * otherwise if it has decimals other than 0 then truncate to one decimal.
 *
 * @example Truncates input with a .0 decimal to integer, otherwise truncate to one decimal
 * ```
 * toOneDecimalOrInteger(6.0) // 6
 * toOneDecimalOrInteger(6.20) // 6.2
 * ```
 *
 * @param value - Value to format
 * @param maxLength - Optional param, which drops the decimal place if the length of the
 * integer part of the number is greater than or equal to maxLength
 *
 * @returns Formatted number
 */
export const toOneDecimalOrInteger = (
  value: number,
  maxLength = 99
): number | null => {
  if (isNaN(value)) return null

  const numberWithOneDecimal = value.toFixed(1)
  const [integer, decimals] = numberWithOneDecimal.split('.')

  return decimals === '0' || integer.length >= maxLength
    ? Number.parseInt(value.toFixed(0))
    : Number.parseFloat(numberWithOneDecimal)
}

/**
 * Abbreviates numbers following these rules:
 * number < 10,000 = Rounds to one decimal
 * number >= 10,000 = Rounds to one decimal and divides per thousands using "k" as unit
 * number > 10,000 < 1,000,000 = Rounds to one decimal and divides per millions using "M" as unit
 *
 * @example Truncates a number to show it in an abbreviated manner to ensure the length is 5 characters or less
 * ```
 * abbreviate(25678) //25.7k
 * abbreviate(187.88e6) //188M
 * ```
 *
 * @param value - Value to format
 *
 * @returns Formatted number
 */
export const abbreviateNumber = (value: number): string | number | null => {
  if (isNaN(value)) return null

  // Using M as abbreviation
  if (value >= 1e6) {
    return toOneDecimalOrInteger(value / 1e6, 3) + 'M'
  }

  // Using k as abbreviation
  if (value >= 1e4) {
    return toOneDecimalOrInteger(value / 1e3, 3) + 'k'
  }

  return toOneDecimalOrInteger(value)
}

/**
 * Calculates percentage of a total.
 *
 * @param value - Value to calculate percentage for
 * @param total - Reference value to calculate percentage from
 * @param roundingStrategy - Rounding strategy to apply to percentage
 *
 * @returns Percentage of total
 */
export const toPercentage = (
  value: number,
  total: number,
  roundingStrategy: RoundingStrategyParam = 'floor'
): number | null => {
  if (isNaN(value) || isNaN(total) || total === 0) return null

  return applyRoundingStrategy(roundingStrategy, (value / total) * 100)
}

/**
 * Calculates percentage decrease between two numbers.
 *
 * @param newValue - Value to calculate decrease for
 * @param originalValue - Starting point to calculate decrease from
 * @param roundingStrategy - Rounding strategy to apply to percentage
 *
 * @returns Decrease in percentage
 */
export const toDecreasePercentage = (
  newValue: number,
  originalValue: number,
  roundingStrategy: RoundingStrategyParam = 'round'
): number | null => {
  const difference = originalValue - newValue
  return toPercentage(difference, originalValue, roundingStrategy)
}

/**
 * Always add two decimals to a number and rounds as necessary via Math.round.
 *
 * @example Integer input
 * ```ts
 * toFloatWithTwoDecimals(6) // 6.00
 * ```
 * @example Float input with more than 2 decimals
 * ```ts
 * toFloatWithTwoDecimals(6.12345) // 6.12
 * ```
 * @example String input with more than 2 decimals and rounding
 * ```ts
 * toFloatWithTwoDecimals('123.115') // 123.12
 * ```
 *
 * @param value - Value to round and format
 *
 * @returns Formatted number
 */
export const toFloatWithTwoDecimals = (
  value: number | string
): number | null => {
  if (isNil(value) || isEmpty(value)) return null

  return Number(
    Math.round(Number(Number.parseFloat(value.toString()) + 'e2')) + 'e-2'
  )
}

// TODO (Core): Add unit tests for this function
/**
 * Percentile = ((L + ( 0.5 x S )) / N) * 100
 * L = Number of values lower than value
 * S = Number of same rank
 * N = Total numbers
 */
export const percentile = (array: number[], value: number) => {
  const currentIndex = 0
  const totalCount = array.reduce((count, currentValue) => {
    const roundedValue = Math.round(value)
    const roundedCurrentValue = Math.round(currentValue)

    if (roundedCurrentValue < roundedValue) {
      return count + 1 // add 1 to `count`
    } else if (roundedCurrentValue === roundedValue) {
      return count + 0.5 // add 0.5 to `count`
    }
    return count + 0
  }, currentIndex)
  return (totalCount * 100) / array.length
}
