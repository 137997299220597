import {
  abbreviateNumber,
  applyRoundingStrategy,
  RoundingStrategy
} from '../../utils/number'
import {ShortCurrencySymbols} from './price'

interface CommonNumberFormattingProps {
  locale: string
  value: number
  currency?: string
  roundingStrategy?: RoundingStrategy
  currencyDisplay?: CurrencyDisplay
}
export type CurrencyDisplay = Intl.NumberFormatOptions['currencyDisplay']

interface AbbreviatedFormattedNumberProps extends CommonNumberFormattingProps {
  useShortCurrency: boolean
}

interface PriceNumberProps extends CommonNumberFormattingProps {
  minimumFractionDigits?: number
  maximumFractionDigits?: number
}

const isLetter = (str: string) =>
  Boolean(str.length === 1 && str.match(/[a-z]/i))

const localizePrice = (
  parts: Intl.NumberFormatPart[],
  abbreviatedPriceNumber: number,
  useShortCurrency: boolean
) => {
  const fraction = (abbreviatedPriceNumber + '').split('.')[1] || '' // the fraction from parts is 2dp, but we don't want that for the abbreviation, so we use the abbreviatedPriceNumber fraction instead
  const price = parts.reduce((prev, curr) => {
    if (curr.type === 'currency' || curr.type === 'literal') {
      return useShortCurrency ? `${prev}` : `${prev}${curr.value}`
    }
    if (curr.type === 'decimal') {
      return fraction.length > 0 ? `${prev}${curr.value}` : `${prev}`
    }
    if (curr.type === 'fraction') {
      return `${prev}${fraction}`
    }
    return `${prev}${curr.value}`
  }, '')

  return price
}

export const formatAbbreviatedPriceValue = ({
  locale,
  roundingStrategy,
  value,
  currency,
  useShortCurrency,
  currencyDisplay
}: AbbreviatedFormattedNumberProps) => {
  const shortCurrencySymbol = useShortCurrency
    ? ShortCurrencySymbols[currency as keyof typeof ShortCurrencySymbols] || ''
    : ''
  const abbreviatedPrice = abbreviateNumber(
    applyRoundingStrategy(roundingStrategy, value)
  )
  if (!abbreviatedPrice) return ''

  const lastChar = abbreviatedPrice.toString().slice(-1)
  const isLastCharLetter = isLetter(lastChar)
  const abbreviatedPriceEndLetter = isLastCharLetter ? lastChar : '' // k or M
  const abbreviatedPriceNumber = isLastCharLetter
    ? Number(abbreviatedPrice.toString().slice(0, -1))
    : Number(abbreviatedPrice)

  const parts = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    currencyDisplay
  }).formatToParts(abbreviatedPriceNumber)

  const localizedPrice = localizePrice(
    parts,
    abbreviatedPriceNumber,
    useShortCurrency
  )

  return `${shortCurrencySymbol}${localizedPrice}${abbreviatedPriceEndLetter}`
}

export const formatPriceValue = ({
  locale,
  roundingStrategy,
  value,
  currency,
  minimumFractionDigits,
  maximumFractionDigits,
  currencyDisplay
}: PriceNumberProps) =>
  new Intl.NumberFormat(locale, {
    minimumFractionDigits,
    maximumFractionDigits,
    style: 'currency',
    currency,
    currencyDisplay
  }).format(applyRoundingStrategy(roundingStrategy, value))
