import React from 'react'
import styled from '@emotion/styled'

import {ControlWithCheckmark} from '@daedalus/atlas/helpers/ControlWithCheckmark'
import {SelectionCard} from '@daedalus/atlas/SelectionCard'
import {cssTheme} from '@daedalus/atlas/themes'

interface Props<T> {
  item: T
  getValue: (item: T) => string
  renderItem: (item: T, isMobile: boolean) => React.ReactNode
  isMobile: boolean
  isActive: boolean
  onClick: (code: string) => void
}

const ListItemWrapper = styled.li`
  cursor: pointer;
  list-style: none;
  display: flex;
  align-items: center;
  padding: ${cssTheme.layout.spacing.s500} 0;
  :not(:last-child) {
    border-bottom: 1px solid ${cssTheme.colors.border.neutral.c100};
  }
  // Setting the height so items with or without checkbox are the same size
  box-sizing: content-box;
  min-height: ${cssTheme.layout.spacing.s600};
`

const FlexGrow = styled.div`
  flex-grow: 1;
`

export const SelectorListItem = <T,>({
  isActive,
  isMobile,
  item,
  onClick,
  renderItem,
  getValue
}: Props<T>) => {
  const value = getValue(item)
  if (isMobile) {
    return (
      <ListItemWrapper
        onClick={() => {
          onClick(value)
        }}
      >
        <FlexGrow>{renderItem(item, isMobile)}</FlexGrow>
        {isActive && <ControlWithCheckmark type="radio" checked name={value} />}
      </ListItemWrapper>
    )
  }

  return (
    <SelectionCard
      size="md"
      type="radio"
      name={value}
      withBorder={isActive}
      withControlButton={isActive}
      selected={isActive}
      alignItems="center"
      onChange={() => {
        onClick(value)
      }}
    >
      {renderItem(item, isMobile)}
    </SelectionCard>
  )
}
