export const sapiErrorToCommonError = async (sapiError: SapiError) => {
  if (!sapiError.json) {
    return {
      request: {
        status: sapiError?.type
      },
      response: {
        data: {
          details: {
            recoverable: true
          },
          message: 'sapi failed to load'
        }
      }
    }
  }

  const request = await sapiError.json()
  return {
    request: {
      ...request,
      status: sapiError.status
    },
    response: {
      data: {
        details: {
          ...request.response?.errors?.[0],
          recoverable: false
        },
        message: sapiError.statusText
      },
      config: {
        headers: sapiError?.headers?.entries()?.toString(),
        params:
          sapiError.url && new URL(sapiError.url)?.searchParams?.toString()
      }
    },
    statusText: sapiError.statusText,
    ok: sapiError.ok
  }
}

interface SapiError {
  json: () => Promise<SapiErrorRequest>
  status: number
  statusText: string
  url: string
  ok: boolean
  headers: SapiErrorHeaders
  type?: string
}

interface SapiErrorRequest {
  response: {
    errors: object[]
  }
}

interface SapiErrorHeaders {
  entries: () => string[][]
}
