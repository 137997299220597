import React from 'react'
import {connect, useDispatch, useSelector} from 'react-redux'
import useParams from 'components/data/URLParams/useParams'
import withURLParamsMapper from 'components/data/URLParams/withURLParamsMapper'
import ErrorPresenter from 'components/errorMonitors/ErrorPresenter'
import {
  BackButtonMountType,
  TRACKING_TIMEOUT_BEFORE_NAVIGATING_BACK
} from 'hooks/useHandleBackButtonClick'
import {bookOfferFinalizationError} from 'modules/book/actions/bookOfferFinalization'
import {getBookingModuleStatus} from 'modules/book/selectors'
import {bookOfferCreationError} from 'modules/book/slice'
import {
  historyBack,
  NavigateToSearch,
  navigateToSearch,
  refreshPage
} from 'modules/browser/actions'
import {PRESENTER_TYPES} from 'modules/common/constants'
import {
  didAnySoldOutErrorHappen,
  getErrorByPresenterType
} from 'modules/common/selectors'
import {all, applySpec, isNil} from 'ramda'
import {Dispatch} from 'redux'
import {bindActionCreators} from 'redux'
import {RootState} from 'store'
import {CommonErrorPayloadFormatType} from 'types/errorResponse'

import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  Category,
  Entity
} from '@daedalus/core/src/analytics/types/Events'
import {useIsAudience} from '@daedalus/core/src/auth/hooks/useIsAudience'
import {selectIsAuthenticated} from '@daedalus/core/src/auth/modules/selectors'
import {defaultBrand} from '@daedalus/core/src/brand/config'

import {overlayConfigurationFactory} from './overlayConfigurationFactory'

interface ErrorOverlayMonitorType {
  genericError: CommonErrorPayloadFormatType
  historyBack: () => void
  navigateToSearch: (navigateToSearch: NavigateToSearch) => void
  refreshPage: () => void
  unknownError: CommonErrorPayloadFormatType
}

const BOOKING_ERRORS = new Set([
  bookOfferFinalizationError.type,
  bookOfferCreationError.type
])

export const ErrorOverlayMonitor = ({
  unknownError,
  genericError,
  historyBack,
  refreshPage,
  navigateToSearch
}: ErrorOverlayMonitorType) => {
  const dispatch = useDispatch()
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const isAudience = useIsAudience()
  const isSoldOut = useSelector((state: RootState) =>
    didAnySoldOutErrorHappen(state)
  )
  const bookingModuleStatus = useSelector(getBookingModuleStatus)
  const {
    rooms,
    checkIn,
    checkOut,
    isDirectToPayment: redirectedFromAccommodation
  } = useParams()

  // BoB brands will also go through our own support, so we can use the default brand here
  const supportUrl = defaultBrand.helpUrl

  if (BOOKING_ERRORS.has(bookingModuleStatus)) return null

  const handleBackToSearch = () => {
    dispatch(
      trackEvent({
        category: Category.User,
        entity: Entity.BackButton,
        action: Action.Clicked,
        payload: {type: BackButtonMountType.error}
      })
    )
    setTimeout(() => {
      if (redirectedFromAccommodation === '1') {
        history.back()
        return
      }

      navigateToSearch({isAuthenticated, isAudience, rooms, checkIn, checkOut})
    }, TRACKING_TIMEOUT_BEFORE_NAVIGATING_BACK)
  }

  if (all(isNil, [unknownError, genericError]) || isSoldOut) {
    return null
  }

  const overlayActions = {
    refreshPage,
    historyBack,
    backToSearch: handleBackToSearch
  }

  const overlayConfiguration = overlayConfigurationFactory(
    unknownError || genericError,
    overlayActions,
    supportUrl
  )

  return (
    <ErrorPresenter
      {...overlayConfiguration}
      buttonAction={overlayActions.backToSearch}
    />
  )
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      historyBack,
      refreshPage,
      navigateToSearch
    },
    dispatch
  )

const mapStateToProps = (state: RootState) => {
  return applySpec({
    unknownError: getErrorByPresenterType(PRESENTER_TYPES.UnknownError),
    genericError: getErrorByPresenterType(PRESENTER_TYPES.GenericError)
  })(state)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withURLParamsMapper(React.memo<ErrorOverlayMonitorType>(ErrorOverlayMonitor)))
