import React, {useCallback} from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'

import {NoStyleButton} from '@daedalus/atlas/helpers/NoStyleButton'
import {Text} from '@daedalus/atlas/Text'
import {cssTheme} from '@daedalus/atlas/themes'

import {TabsContext} from './Tabs'

interface Props {
  value: string
}

const ActiveUnderline = styled.span<{
  isActive: boolean
}>(
  ({isActive}) => css`
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: ${cssTheme.colors.border.neutral.c950};
    ${!isActive &&
    css`
      background-color: ${cssTheme.colors.content.neutral.c600};
    `}
    opacity: 0;
    transition: all 0.3s;
  `
)

//We can't have 18px margins from theme spacing, needs to be combined 16+2 to match the design (confirmed with @Design)
const AdditionalMargin = styled.div`
  padding: ${cssTheme.layout.spacing.s100} 0;
`

const TabWrapper = styled.li<{
  isActive: boolean
}>(
  ({isActive}) => css`
    display: flex;
    list-style: none;
    position: relative;
    ${!isActive &&
    css`
      color: ${cssTheme.colors.content.neutral.c600};
    `}
    ${isActive &&
    css`
      > ${ActiveUnderline} {
        opacity: 1;
      }
    `}
    :hover {
      > ${ActiveUnderline} {
        opacity: 1;
      }
    }
  `
)

const StyledButton = styled(NoStyleButton)`
  padding: ${cssTheme.layout.spacing.s500} 0;
`

export const Tab: React.FC<Props> = ({value, children}) => {
  const {onChange, value: activeTab} = React.useContext(TabsContext)

  const onClick = useCallback(() => {
    onChange(value)
  }, [onChange, value])

  const isActive = activeTab === value

  return (
    <TabWrapper isActive={isActive}>
      <ActiveUnderline isActive={isActive} />
      <StyledButton onClick={onClick}>
        <AdditionalMargin>
          <Text variant="labelS">{children}</Text>
        </AdditionalMargin>
      </StyledButton>
    </TabWrapper>
  )
}
