import {UserTier} from '../../offer/business/privateDeals'

export interface CustomAppDataAttributes {
  tier?: UserTier[]
  loginCount?: number
  auth0_id?: string
  phone_verified?: boolean
}

export interface CustomAppData {
  'custom:app_data': CustomAppDataAttributes
}

export enum AuthenticationProvider {
  Google = 'Google',
  Facebook = 'Facebook',
  Apple = 'Apple'
}

/** @deprecated use AuthenticationProvider - We will keep CognitoHostedUIIdentityProvider until we migrate mobile-app to amplify v6 */
export enum CognitoHostedUIIdentityProvider {
  Cognito = 'COGNITO',
  Google = 'Google',
  Facebook = 'Facebook',
  Amazon = 'LoginWithAmazon',
  Apple = 'SignInWithApple'
}

export interface UserAttributes {
  given_name?: string
  family_name?: string
  phone_number?: string
  phone_number_verified?: boolean
  picture?: string
  email?: string
  email_verified?: boolean
  sub?: string
  /**
   * custom:user_data is a JSON stringified object containing the user data that can be modified by the user itself.
   */
  'custom:user_data'?: string
  /**
   * custom:app_data is a stringified JSON object that is only readable by front-end (no write access). user `tier` is stored in this field.
   * Example: '{"tier":["plus"]}'
   */
  'custom:app_data'?: string
  identities?: string
}

//  Represents the identity object associated with a Cognito user attribute's identities, which is a stringified JSON array.
export interface UserAttributeIdentities {
  userId: string
  providerName: string
  providerType: string
  issuer: null
  primary: boolean
  dateCreated: number
}

export interface User {
  attributes: UserAttributes
  id?: string
  username?: string
  createdAt?: string
  updatedAt?: string
}

export interface CognitoSession {
  username: string
  pool: Pool
  Session: string
  client: Client
  signInUserSession: SignInUserSession
  authenticationFlowType: string
  keyPrefix: string
  userDataKey: string
}

export interface Client {
  endpoint: string
  fetchOptions: Record<string, unknown>
}
export enum UserPoolName {
  Dev = 'dev',
  EtripProd = 'etrip-prod',
  EtripStg = 'etrip-stg',
  FindhotelProd = 'findhotel-prod',
  FindhotelStg = 'findhotel-stg'
}

export type UserIdentifiableTraits = {
  legacy_id: string | null
  tier?: UserTier[]
  userPoolName: UserPoolName | null
}

export enum UserPoolId {
  Dev = 'eu-west-1_BdQ3nsIJ8',
  EtripProd = 'eu-west-1_ZJ1HZg9RJ',
  EtripStg = 'eu-west-1_ilOJ69tYk',
  FindhotelProd = 'eu-west-1_lNHHlN2Fh',
  FindhotelStg = 'eu-west-1_LTpBgi2PL'
}

export interface Pool {
  userPoolId: UserPoolId | null
  clientId: string
  client: Client
  advancedSecurityDataCollectionFlag: boolean
}

export interface SignInUserSession {
  idToken: IDToken
  refreshToken: RefreshToken
  accessToken: AccessToken
  clockDrift: number
}

export interface AccessToken {
  jwtToken: string
  payload: AccessTokenPayload
}

export interface AccessTokenPayload {
  sub: string
  iss: string
  client_id: string
  origin_jti: string
  event_id: string
  token_use: string
  scope: string
  auth_time: number
  exp: number
  iat: number
  jti: string
  username: string
}

export interface IDToken {
  jwtToken: string
  payload: IDTokenPayload
}

export interface IDTokenPayload {
  sub: string
  iss: string
  phone_number_verified: boolean
  'cognito:username': string
  origin_jti: string
  aud: string
  event_id: string
  token_use: string
  auth_time: number
  phone_number: string
  exp: number
  iat: number
  jti: string
}

export interface RefreshToken {
  token: string
}

export enum CognitoErrorCodes {
  UsernameExistsException = 'UsernameExistsException',
  UserLambdaValidationException = 'UserLambdaValidationException'
}

export interface CognitoError {
  name: string
  code: CognitoErrorCodes
  message?: string
}
