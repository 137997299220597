import {updatePrices} from 'modules/offerCheck/slice'
import bofhDefaultHeaders from 'services/bofhDefaultHeaders'
import Settings from 'Settings'
import buildUrl from 'utils/buildUrl'
import {urlParams} from 'utils/urlParams'

import {
  Booking,
  MerchantOfRecord
} from '@daedalus/core/src/api-types/bovio/response/booking'
import {getBovioApiKey} from '@daedalus/core/src/booking/utils'
import {BrandCode} from '@daedalus/core/src/brand/types'
import {
  OfferCheckApiRequestParams,
  OfferCheckAPIResponse,
  SplitBookingOfferCheckAPIResponse,
  SplitBookingOfferCheckUpdatedResponse,
  UnifiedOfferCheckData
} from '@daedalus/core/src/offer/types/OfferCheck'
import {adaptOfferCheckRoom} from '@daedalus/core/src/offer/utils/adaptOfferCheckRoom'
import {getQueryStringObject} from '@daedalus/core/src/utils/url'
import {
  SbOfferParamPartial,
  UrlCheckoutParamsType
} from '@daedalus/core/src/utils/url/types/UrlParams'

import {sumBookingPrices} from './sumBookingPrices'
import {PaymentFlowType} from './types'

export enum OfferCheckedIn {
  RSP = 'rsp' // The only valid value for now, passed to the Booking_OfferCheck_Served/Errored events
}
const appEnv = Settings.get('REACT_APP_ENV')

export interface OfferCheckUrlParams extends UrlCheckoutParamsType {
  searchId: string
  offerCheckedIn?: OfferCheckedIn
  pc?: string
  label?: string
}

export const buildOfferCheckEndpoint = (urlParams: OfferCheckUrlParams) => {
  const {
    anonymousId,
    currency,
    roomsBundleId,
    deviceType,
    redirectId,
    searchId,
    offerCheckedIn,
    pc,
    offer_token,
    bm,
    mbm,
    sg,
    nc,
    exp,
    label,
    splitBookingMergedBundle
  } = urlParams

  const BoFhApi = Settings.get('REACT_APP_BOFH_ENDPOINT')

  const pcValue = pc === 'f' || pc === 't' ? pc : undefined
  const hasSplitBookingParams = Boolean(roomsBundleId)

  const searchParams: Record<
    string,
    string | undefined | null | SbOfferParamPartial[]
  > = {
    anonymousId,
    currency,
    bundleId: roomsBundleId,
    deviceType,
    redirectId,
    searchId,
    offerCheckedIn,
    bm,
    mbm,
    sg,
    nc,
    exp,
    pc: pcValue,
    label
  }

  if (!offer_token && !hasSplitBookingParams) {
    return null
  }

  const filteredSearchParams = Object.fromEntries(
    Object.entries(searchParams).filter(([, value]) => value !== undefined)
  )

  if (hasSplitBookingParams && splitBookingMergedBundle) {
    return buildUrl(
      `${BoFhApi}bundles/${roomsBundleId}/check`,
      filteredSearchParams,
      {
        arrayFormat: 'indices'
      }
    )
  }

  if (hasSplitBookingParams) {
    return buildUrl(`${BoFhApi}offers/sb/check`, filteredSearchParams, {
      arrayFormat: 'indices'
    })
  }

  return buildUrl(`${BoFhApi}offers/${offer_token}/check`, filteredSearchParams)
}

export const getOfferCheckApiRequestParams = (
  url: string,
  brandCode: BrandCode,
  headers?: Record<string, string>
): OfferCheckApiRequestParams<
  UnifiedOfferCheckData,
  OfferCheckAPIResponse
> => ({
  url,
  experimentApiKey: getBovioApiKey({
    brandCode,
    appEnv,
    urlParams: getQueryStringObject()
  }),
  headers: {
    ...bofhDefaultHeaders,
    ...headers
  },
  transformResponseFunc: regularOfferCheckTransformResponseFunc
})

export const getSplitBookingOfferCheckApiRequestParams = (
  url: string,
  brandCode: BrandCode,
  headers?: Record<string, string>
): OfferCheckApiRequestParams<
  UnifiedOfferCheckData,
  SplitBookingOfferCheckAPIResponse
> => ({
  url,
  experimentApiKey: getBovioApiKey({
    brandCode,
    appEnv,
    urlParams: getQueryStringObject()
  }),
  headers: {
    ...bofhDefaultHeaders,
    ...headers
  },
  transformResponseFunc: splitBookingTransformResponseFunc
})

export const getMergedBundleSplitBookingOfferCheckRequestParams = (
  url: string,
  brandCode: BrandCode,
  headers?: Record<string, string>
): OfferCheckApiRequestParams<
  UnifiedOfferCheckData,
  SplitBookingOfferCheckUpdatedResponse
> => ({
  url,
  experimentApiKey: getBovioApiKey({
    brandCode,
    appEnv,
    urlParams: getQueryStringObject()
  }),
  headers: {
    ...bofhDefaultHeaders,
    ...headers
  },
  transformResponseFunc: splitBookingMergedBundleTransformResponseFunc
})

export const regularOfferCheckTransformResponseFunc = (
  response: OfferCheckAPIResponse
): UnifiedOfferCheckData => {
  return {
    offers: [
      {
        ...response,
        room: adaptOfferCheckRoom(response.room),
        moduleState: '',
        meals: response.meals?.[0]
      }
    ],
    providerTraderInformation: response.providerTraderInformation,
    cancellationPenalties: response.cancellationPenalties,
    links: response.links,
    merchantOfRecord: response.merchantOfRecord,
    status: response.status,
    prices: response.prices ? updatePrices(response.prices) : [],
    httpRequestId: response.httpRequestId
  }
}

export const getPaymentFlowType = () => {
  const {bookingId, offer_token, roomsBundleId: bundleId} = urlParams()
  if (bookingId) return PaymentFlowType.ExistingBooking
  if (bundleId) return PaymentFlowType.SplitBooking
  if (offer_token) return PaymentFlowType.RegularOffer
}

export const splitBookingMergedBundleTransformResponseFunc = (
  response: SplitBookingOfferCheckUpdatedResponse
): UnifiedOfferCheckData => {
  return {
    offers: response.rooms.map(room => {
      const {checkIn, checkOut, providerCode} = room

      return {
        checkIn,
        checkOut,
        providerCode,
        prices: [],
        room: adaptOfferCheckRoom(room),
        meals: room.meals?.[0],
        moduleState: '',
        cancellationPenalties: undefined,
        links: [],
        merchantOfRecord: response.merchantOfRecord,
        status: response.status,
        refundable: false
      }
    }),
    providerTraderInformation: null,
    merchantOfRecord: response.merchantOfRecord,
    links: response.links,
    cancellationPenalties: response.cancellationPenalties,
    status: response.status,
    prices: updatePrices(response.prices),
    httpRequestId: response.httpRequestId
  }
}

export const splitBookingTransformResponseFunc = (
  response: SplitBookingOfferCheckAPIResponse
): UnifiedOfferCheckData => {
  return {
    offers: response.offers?.map(offer => {
      return {
        ...offer,
        prices: updatePrices(offer.prices),
        room: adaptOfferCheckRoom(offer.room),
        meals: offer.meals?.[0],
        moduleState: ''
      }
    }),
    providerTraderInformation: null,
    merchantOfRecord: MerchantOfRecord.Findhotel,
    links: response.links,
    cancellationPenalties: [], // each offer has its own in SB flow
    status: response.status,
    prices: response.totalPrices ? updatePrices(response.totalPrices) : [],
    httpRequestId: response.httpRequestId
  }
}

export const existingBookingOfferCheckDataTransform = (
  rawBookings?: Booking[]
): UnifiedOfferCheckData | undefined => {
  if (!rawBookings?.length) return undefined

  //booking used as a reference for medatada when it's a multi booking case (SB)

  const mainBooking = rawBookings[0]
  return {
    offers: rawBookings.map(booking => ({
      ...booking,
      links: booking.links,
      meals: booking.rooms[0].boardType,
      room: adaptOfferCheckRoom({
        //room property is possibly not needed here for existing booking, consider clearing after checking CL/DF
        ...booking.rooms[0],
        displayName: booking.rooms[0].name
      })
    })),
    bookings: rawBookings,
    rooms: mainBooking.rooms,
    merchantOfRecord: mainBooking.merchantOfRecord,
    links: mainBooking.links,
    status: mainBooking.status,
    prices: updatePrices(
      sumBookingPrices(rawBookings.map(booking => booking.prices))
    ),
    cancellationPenalties: mainBooking.cancellationPenalties,
    bookingInfo: {
      customerSupport: mainBooking.customerSupport,
      billing: mainBooking.billing,
      confirmationId: mainBooking.confirmationId,
      customer: mainBooking.customer,
      id: mainBooking.id,
      source: mainBooking.source,
      expiresAt: mainBooking.expiresAt,
      payment: mainBooking.payment
    }
  }
}

export type GetOfferCheckApiRequestParamsReturn = ReturnType<
  typeof getOfferCheckApiRequestParams
>
