import {getDomainName} from '../../../utils/url'
import {cookieSecuritySettings} from '../../utils/cookies/cookieSecuritySettings'

const maxAge = 1 // 1 day
const name = 'userCountryCode'

const config = {
  name,
  options: {
    maxAge,
    domain: getDomainName(window.location.hostname),
    ...cookieSecuritySettings(true)
  }
}

export default config
