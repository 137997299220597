import React, {useContext, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {css} from '@emotion/react'
import styled from '@emotion/styled'

import {MOBILE_HEADER_HEIGHT} from '@daedalus/atlas/CompactHeader'
import {PageWidthContainer as PageWidthContainerElement} from '@daedalus/atlas/helpers/PageWidthContainer'
import {cssTheme} from '@daedalus/atlas/themes'
import {mq} from '@daedalus/atlas/utils/breakpoints'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  Category,
  Entity,
  Team
} from '@daedalus/core/src/analytics/types/Events'
import {selectUser} from '@daedalus/core/src/auth/modules/selectors'
import {Brand} from '@daedalus/core/src/brand/types'

import {ReferralLinkContext} from '../ReferralLinkProvider'
import {ReferralProgram} from './ReferralProgram'
import {SideBar} from './ReferralProgram/SideBar'

const SIDEBAR_MAX_WIDTH = 294

const PageContainerWrapper = styled.div`
  height: calc(100vh - ${MOBILE_HEADER_HEIGHT}px);
  flex-grow: 1;
  background: ${cssTheme.colors.background.neutral.c000};

  ${mq.desktopXs(css`
    height: auto;
    padding: ${cssTheme.layout.spacing.s500} 0;
    background: ${cssTheme.colors.background.neutral.c050};
  `)}
`

const PageWidthContainer = styled(PageWidthContainerElement)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${mq.desktopXs(css`
    padding: 0 ${cssTheme.layout.spacing.s500};
  `)}
`

const PageGrid = styled.div`
  margin-bottom: ${cssTheme.layout.spacing.s600};

  ${mq.desktopXs(css`
    display: grid;
    grid-template-columns:
      ${SIDEBAR_MAX_WIDTH}px
      minmax(min-content, auto);
    gap: ${cssTheme.layout.spacing.s600};
    margin-bottom: 0;
    margin-top: 0;
  `)}
`

const useReferralViewTracking = () => {
  const dispatch = useDispatch()
  const userId = useSelector(selectUser)?.attributes?.sub
  const {referralShortLink} = useContext(ReferralLinkContext)

  useEffect(() => {
    if (userId && referralShortLink)
      dispatch(
        trackEvent({
          category: Category.System,
          entity: Entity.ReferralLinkOverlay,
          action: Action.Viewed,
          payload: {
            userId,
            referralLink: referralShortLink
          },
          team: Team.Retention
        })
      )
  }, [userId, referralShortLink])
}

interface ReferralContentPropTypes {
  brand: Brand
  anonymousId: string
}

export const ReferralContent = ({
  brand,
  anonymousId
}: ReferralContentPropTypes) => {
  useReferralViewTracking()

  return (
    <PageContainerWrapper>
      <PageWidthContainer>
        <PageGrid>
          <SideBar
            currentPageId="membership.InviteFriendsToGems"
            anonymousId={anonymousId}
            brand={brand}
          />
          <ReferralProgram />
        </PageGrid>
      </PageWidthContainer>
    </PageContainerWrapper>
  )
}
