import {
  ComparisonOfferContextType,
  ComparisonOffersListContextType,
  OfferContextType,
  OffersListContextType,
  PriceContextType
} from 'types/trackingContext'

import {PriceContext} from '@daedalus/core/src/analytics/types/Events'
import {PhoneNumberRequest} from '@daedalus/core/src/booking/types/BookingRequest'
import {ErrorResponse} from '@daedalus/core/src/booking/types/ErrorResponse'
import {SplitBooking} from '@daedalus/core/src/room/types/room'
import {UrlCheckoutParamsType} from '@daedalus/core/src/utils/url/types/UrlParams'

export const EventName = {
  BACK_TO_RSP_CLICK: 'HotelPages.BackToRspClick',
  BACK_TO_SEARCH_CLICK: 'HotelPages.BackToSearchClick',
  BILLING_DETAILS_VALIDATION_ERROR: 'HotelPages.BillingDetailsValidationError',
  BOOKING_ERROR: 'HotelPages.BookingError',
  BOOKING_SUCCESS: 'HotelPages.BookingSuccess',
  CANCELLATION_TERMS_CLICK: 'HotelPages.CancellationTermsClick',
  CHECK_IN_INSTRUCTIONS_CLICK: 'HotelPages.CheckInInstructionsClick',
  CONFIRMATION_PAGE_IMPRESSION: 'HotelPages.ConfirmationPageImpression',
  COMPARISON_OFFER_CLICK: 'HotelPages.ComparisonOfferClick',
  CURRENCY_CHANGE: 'HotelPages.CurrencyChange',
  SOLD_OUT_DATE_CHANGE_CLICK: 'HotelPages.SoldOutDateChangeClick',
  DEAL_DETAILS_CLICK: 'HotelPages.DealDetailsClick',
  DUE_AT_PROPERTY_CLICK: 'HotelPages.DueAtPropertyClick',
  FILTER_BUTTON_CLICK: 'HotelPages.FilterButtonClick',
  HOTEL_INFO_ACTIVITIES_CLICK: 'HotelPages.ActivitiesClick',
  HOTEL_INFO_AMENITIES_CLICK: 'HotelPages.AmenitiesClick',
  HOTEL_INFO_CHECKIN_CHECKOUT_CLICK: 'HotelPages.CheckInCheckOutClick',
  CALL_TO_BOOK_CLICK: 'HotelPages.CallToBookClick',
  HOTEL_INFO_IMPORTANT_INFORMATION_CLICK:
    'HotelPages.ImportantInformationClick',
  HOTEL_INFO_INTERNET_CLICK: 'HotelPages.InternetClick',
  HOTEL_INFO_OPTIONAL_EXTRAS_CLICK: 'HotelPages.OptionalExtrasClick',
  HOTEL_INFO_PARKING_CLICK: 'HotelPages.ParkingClick',
  HOTEL_INFO_SERVICES_CLICK: 'HotelPages.ServicesClick',
  LANGUAGE_CHANGE: 'HotelPages.LanguageChange',
  LOCAL_TAX_CLICK: 'HotelPages.LocalTaxClick',
  LOCKED_ROOM_OFFER_CLICK: 'HotelPages.LockedRoomOfferClick',
  MORE_ROOM_DETAILS_CLICK: 'HotelPages.MoreRoomDetailsClick',
  NPS_FORM_SUBMITTED: 'HotelPages.NpsFormSubmitted',
  OTHER_FEE_CLICK: 'HotelPages.OtherFeeClick',
  PAGE_ERROR: 'HotelPages.PageError',
  PAYMENT_BUTTON_CLICK: 'HotelPages.PaymentButtonClick',
  PAYMENT_DETAILS_VALIDATION_ERROR: 'HotelPages.PaymentDetailsValidationError',
  PAYMENT_PAGE_IMPRESSION: 'HotelPages.PaymentPageImpression',
  PCI_PROXY_CONNECTION_ERROR: 'HotelPages.PCIProxyConnectionError',
  PERSONAL_DETAILS_VALIDATION_ERROR:
    'HotelPages.PersonalDetailsValidationError',
  PROPERTY_FEES_CLICK: 'HotelPages.PropertyFeesClick',
  RESORT_FEE_CLICK: 'HotelPages.ResortFeeClick',
  ROOM_OFFER_CLICK: 'HotelPages.RoomOfferClick',
  SEARCH_CLICK: 'HotelPages.SearchClick',
  SECURE_FIELDS_DOM_ERROR: 'HotelPages.SecureFieldsDomError',
  SECURE_FIELDS_DOM_WAIT: 'HotelPages.SecureFieldsDomWait',
  SOLD_OUT_SEE_NEARBY_PROPERTIES: 'HotelPages.SoldOutSeeNearbyPropertiesClick',
  PSD2_CHALLENGE: 'HotelPages.PSD2Challenge',
  TAXES_AND_FEES_CLICK: 'HotelPages.TaxesAndFeesClick',
  API_ADDRESS_CHOSEN: 'HotelPages.APIAddressChosen',
  ADDRESS_ENTRY_MODE_SWITCHED: 'HotelPages.AddressEntryModeSwitched',
  GMAPS_API: 'HotelPages.GoogleMapsAPI',
  SHOW_COOKIE_BANNER: 'HotelPages.ShowCookieBanner',
  SUBMIT_COOKIE_BANNER: 'HotelPages.SubmitCookieBanner',
  MANAGE_BOOKING_CLICK: 'HotelPages.ManageBookingClick',
  ACTIVATE_PLUS_CLICK: 'HotelPages.ActivatePlusClick',
  ACTIVATE_PLUS_BUTTON_IMPRESSION: 'HotelPages.ActivatePlusButtonImpression',
  VIEW_BENEFITS_CLICK: 'HotelPages.ViewBenefitsClick',
  CANCELLATION_TERMS_LABEL_CLICKED: 'HotelPages.CancellationTermsLabelClicked',
  RESEND_EMAIL_BUTTON_CLICKED: 'HotelPages.ResendEmailButtonClicked',
  HOTEL_INFO_DEPOSIT_CLICKED: 'HotelPages.SecurityDepositClick'
}

export const EventCategory = {
  BOOKING: 'booking',
  CANCELLATION: 'cancellation',
  FEEDBACK_FORM: 'feedback_form',
  LOCKED_OFFER: 'locked_offer',
  OFFER: 'offer',
  PAGE_ERROR: 'page_error',
  PAGE_LOAD: 'page_load',
  ROOM_ANOMALY: 'room_anomaly',
  ROOM_OFFER: 'room_offer',
  SEARCH_BAR: 'search_bar',
  SIGN_IN: 'sign_in',
  USER_ACTION: 'user_action',
  VALIDATION_ERROR: 'validation_error',
  PAGE_POPUP: 'page_popup'
}

export const EventSpace = {
  BOOKING_CONFIRMATION_PAGE: 'booking_confirmation_page',
  PAYMENT_PAGE: 'payment_page',
  CHECKOUT: 'checkout'
}

export const EventAction = {
  CLICK: 'click',
  ERROR: 'error',
  SHOWN: 'shown',
  SUCCESS: 'success',
  SUBMIT: 'submit'
}

interface Context {
  offer?: OfferContextType | null
  displayPrice?: PriceContextType | null
  price?: PriceContext | null
  error?: ErrorResponse
  comparisonOffer?: ComparisonOfferContextType
  offersList?: OffersListContextType
  comparisonOffersList?: ComparisonOffersListContextType
  nps?: {
    nps_score: number
    open_question?: string
  }
  splitBooking?: SplitBooking | null
}

export type trackEventV1Type = {
  name: string
  category: string
  space?: string
  action: string
  urlParams?: UrlCheckoutParamsType
  label?: string | string[] | string[][] | number | null
  value?:
    | string
    | number
    | (string | boolean | PhoneNumberRequest)[][]
    | Error
    | null
  context?: Context
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: Record<string, any>
}

export type ActionType = {
  event: trackEventV1Type
  type: string
}

export const TRACK_EVENT_V1 = 'TRACK_EVENT_V1'

/**
 * @deprecated - use the new formalized event system (trackEvent -> analyticsV2)
 */
const trackEventV1 = (event: trackEventV1Type): ActionType => ({
  event,
  type: TRACK_EVENT_V1
})

export default trackEventV1
