import Settings from 'Settings'
import {RootState} from 'store'

import {
  getOrGenerateAnonymousId,
  persistAnonymousId,
  removeAnonymousIdFromUrl
} from '@daedalus/core/src/_web/anonymousId'
import {getBrandCodeFromUrlOrCookie} from '@daedalus/core/src/_web/brand/utils'
import {getPersistedUserCountryCode} from '@daedalus/core/src/_web/userConfig/userCountry'
import {getPersistedUserIp} from '@daedalus/core/src/_web/userConfig/userIp'
import {getUrlParams} from '@daedalus/core/src/utils/url'
import {generateSearchId} from '@findhotel/sapi'

import {MetaState} from './slice'

export const augmentMetaState = (
  initialState: Partial<RootState>
): MetaState => {
  const anonymousId = getOrGenerateAnonymousId()
  persistAnonymousId(anonymousId)
  removeAnonymousIdFromUrl()

  const userIp = getPersistedUserIp()
  const userCountry = getPersistedUserCountryCode()
  const {deviceId, searchId} = getUrlParams() as {
    deviceId: MetaState['deviceId']
    searchId: MetaState['searchId']
  }

  const env = Settings.get('REACT_APP_ENV')
  const brandCode = getBrandCodeFromUrlOrCookie(env)

  return {
    brandCode,
    searchId: searchId || generateSearchId(),
    anonymousId,
    userIp,
    userCountry,
    deviceId,
    // Allow tests to overwrite initial state
    ...(initialState?.meta || {})
  }
}

export {default} from './slice'
