import * as React from 'react'
import {Link} from 'react-router-dom'
import {css} from '@emotion/react'
import styled from '@emotion/styled'

import {Divider} from '@daedalus/atlas/Divider'
import {Icon} from '@daedalus/atlas/Icon'
import {cssTheme} from '@daedalus/atlas/themes'
import {FormattedMessageWrapper} from '@daedalus/core/src/localization/components/FormattedMessage'

const idToIcon = {
  account: 'UserCircle',
  membership: 'Sparkles',
  'membership.InviteFriendsToGems': 'SpeechBubblePlus',
  getHelp: 'Help',
  myBookings: 'PersonWithLuggage'
} as const

interface Props {
  currentPageId: string
  pageId: keyof typeof idToIcon
  label: string
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void | undefined
  to?: string
  /** Id of the text displayed. If not present pageId will be used. */
  textId?: string
  /** Values to be used inside a formatted text */
  values?: Record<string, React.ReactNode>
}

type SideMenuLinkProps = {
  active: boolean
}

const SideMenuLink = styled(Link)<SideMenuLinkProps>(
  ({active}) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    gap: ${cssTheme.layout.spacing.s300};
    padding: ${cssTheme.layout.spacing.s500} ${cssTheme.layout.spacing.s400}
      ${cssTheme.layout.spacing.s500} ${cssTheme.layout.spacing.s500};
    font-size: ${cssTheme.fonts.size.md};
    font-weight: ${cssTheme.fonts.weight.lg};
    font-family: ${cssTheme.fonts.family.default};
    ${active &&
    `
      border-right: 3px solid ${cssTheme.colors.border.interactive.c500};
    `}

    &,
    &:visited,
    &:hover {
      color: ${cssTheme.colors.content.neutral.c950};
      text-decoration: none;
    }

    &:hover {
      ${ItemContent} {
        position: relative;
      }

      ${ItemContent}:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 4px;
        border-bottom: 1.5px solid ${cssTheme.colors.border.neutral.c950};
      }
    }
  `
)

const Label = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${cssTheme.layout.spacing.s300};
`

const IconWrapper = styled.div`
  color: ${cssTheme.colors.content.neutral.c600};
`

const ItemContent = styled(FormattedMessageWrapper)`
  display: flex;
  gap: ${cssTheme.layout.spacing.s200};
`

export const AccountSideMenuItem = ({
  currentPageId,
  pageId,
  label,
  onClick,
  textId,
  to,
  values
}: Props) => (
  <li>
    <Divider />
    <SideMenuLink
      to={to || ''}
      onClick={onClick}
      active={currentPageId === pageId}
    >
      <Label>
        <IconWrapper>
          <Icon name={idToIcon[pageId]} />
        </IconWrapper>
        <ItemContent
          id={textId || pageId}
          defaultMessage={label}
          values={values}
        />
      </Label>
      {pageId === 'getHelp' ? <Icon name="Share" /> : null}
    </SideMenuLink>
  </li>
)
