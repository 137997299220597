import React, {useCallback} from 'react'
import {useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {css} from '@emotion/react'
import styled from '@emotion/styled'
import {Field, Form, Formik} from 'formik'
import {equals} from 'ramda'

import {Button} from '@daedalus/atlas/Button'
import {useDeviceLayout} from '@daedalus/atlas/context/deviceLayout'
import {useToastController} from '@daedalus/atlas/context/toastController'
import {Divider} from '@daedalus/atlas/Divider'
import {InputWrapperFormik} from '@daedalus/atlas/helpers/InputWrapperFormik'
import {Icon} from '@daedalus/atlas/Icon'
import {InputText} from '@daedalus/atlas/InputText'
import {Label} from '@daedalus/atlas/Label'
import {Link} from '@daedalus/atlas/Link'
import {Text} from '@daedalus/atlas/Text'
import {cssTheme} from '@daedalus/atlas/themes'
import {mq} from '@daedalus/atlas/utils/breakpoints'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  Category,
  Entity,
  Team
} from '@daedalus/core/src/analytics/types/Events'
import {
  getCognitoUserAttributes,
  getUserProfileUpdatePending as getCognitoUserProfileUpdatePending
} from '@daedalus/core/src/auth/modules/selectors'
import {updateUserAttributes} from '@daedalus/core/src/auth/modules/thunks'
import {User} from '@daedalus/core/src/auth/types/Cognito'
import {Brand} from '@daedalus/core/src/brand/types'
import {checkIsBrandVio} from '@daedalus/core/src/brand/utils'
import {FormattedMessageWrapper} from '@daedalus/core/src/localization/components/FormattedMessage'

import {toastMessages} from '../../../constants/notificationMessages'
import {DeleteUserProfile} from '../DeleteUserProfile'

const FORM_MAX_WIDTH = 352
const ACCOUNT_FORM_ID = 'account-form-id'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${cssTheme.layout.spacing.s600};

  ${mq.desktopXs(css`
    padding: ${cssTheme.layout.spacing.s700} ${cssTheme.layout.spacing.s700}
      56px;
  `)}
`

export const FormExtended = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: ${cssTheme.layout.spacing.s700};

  ${mq.desktopXs(css`
    width: ${FORM_MAX_WIDTH}px;
    margin: 0 auto ${cssTheme.layout.spacing.s700};
  `)}
`

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 ${cssTheme.layout.spacing.s500};
  gap: ${cssTheme.layout.spacing.s400};

  ${mq.desktopXs(css`
    padding: 0;
  `)}
`

/**
 * Wrapper component to change the appearance of the disabled text input, to make it look like it's not disabled,
 * it applies custom styles to the inner component.
 */
const InputTextStyledWrapper = styled.div`
  width: 100%;

  input:disabled {
    pointer-events: none;
    color: ${cssTheme.colors.content.neutral.c950};
    background: ${cssTheme.colors.background.neutral.c000};
  }
`

const StyledLink = styled(Link)`
  span {
    color: ${cssTheme.colors.content.neutral.c000};
  }
`

const DeleteAccountWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const getToastContent = (brand: Brand) => (
  <FormattedMessageWrapper
    id="account.emailNotEditable"
    defaultMessage="We don’t support editing email address at this point. If you need help please <ContactSupport></ContactSupport>"
    values={{
      ContactSupport: () => (
        <StyledLink href={brand.contactUrl} target="_blank" rel="noreferrer">
          <FormattedMessageWrapper
            id="contactSupport"
            defaultMessage="contact support"
          />
        </StyledLink>
      )
    }}
  />
)

interface PersonalDetailsFormPropTypes {
  brand: Brand
}

export const PersonalDetailsForm = ({brand}: PersonalDetailsFormPropTypes) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const userAttributes = useSelector(getCognitoUserAttributes)
  const user = {
    given_name: userAttributes?.given_name,
    family_name: userAttributes?.family_name,
    phone_number: userAttributes?.phone_number,
    picture: userAttributes?.picture,
    email: userAttributes?.email
  }
  const isVio = checkIsBrandVio(brand)

  const toast = useToastController()

  const onEmailClicked = () => {
    toast.open(getToastContent(brand))
    dispatch(
      trackEvent({
        category: Category.User,
        entity: Entity.AccountEmail,
        action: Action.Clicked,
        team: Team.Retention
      })
    )
  }

  const isSubmitting = useSelector(getCognitoUserProfileUpdatePending)
  const {isMobile} = useDeviceLayout()

  const onSubmit = useCallback(
    async (user: User['attributes']) => {
      try {
        await dispatch(
          updateUserAttributes({
            given_name: user?.given_name,
            family_name: user?.family_name,
            phone_number: user?.phone_number,
            picture: user?.picture,
            email: user?.email
          })
        )
        toast.open(
          <FormattedMessageWrapper {...toastMessages.userDataSaved} />,
          {icon: <Icon name="Checkmark" />}
        )
      } catch (error) {
        toast.warning(
          <FormattedMessageWrapper {...toastMessages.userDataSaveError} />,
          {icon: <Icon name="FaceSad" />}
        )
      }
    },
    [dispatch]
  )

  const handleSubmit = useCallback(
    (formData: User['attributes']) => {
      const isSameData = equals(
        [formData.given_name, formData.family_name],
        [user.given_name, user.family_name]
      )
      if (!isSameData) onSubmit(formData)
    },
    [onSubmit, user]
  )

  const deleteAccountText = isVio
    ? {
        id: 'membership.permanentlyDeleteMyVioAccount',
        defaultMessage: 'Permanently delete my Vio.com account'
      }
    : {
        id: 'membership.permanentlyDeleteAccount',
        defaultMessage: 'Permanently delete my account'
      }

  return (
    <Wrapper>
      {!isMobile ? (
        <>
          <Text variant="titleXL">
            <FormattedMessageWrapper
              id="accountContent.title"
              defaultMessage="Account"
            />
          </Text>

          <Divider />

          <Text variant="titleL">
            <FormattedMessageWrapper
              id="accountContent.personalInfo"
              defaultMessage="Personal info"
            />
          </Text>
        </>
      ) : null}

      {(user?.phone_number || user?.email) && (
        <Formik initialValues={user} onSubmit={handleSubmit}>
          <FormExtended id={ACCOUNT_FORM_ID}>
            <FormWrapper>
              <Label
                forId="email"
                variant="bodyS"
                value={
                  <FormattedMessageWrapper
                    id="firstName"
                    defaultMessage="First name"
                  />
                }
              >
                <InputWrapperFormik Field={Field} name="given_name">
                  <InputText
                    isPrivate
                    id="given_name"
                    dataId="AccountFirstNameField"
                    name="given_name"
                    placeholder={intl.formatMessage({
                      id: 'firstName',
                      defaultMessage: 'First name'
                    })}
                  />
                </InputWrapperFormik>
              </Label>

              <Label
                forId="lastName"
                variant="bodyS"
                value={
                  <FormattedMessageWrapper
                    id="lastName"
                    defaultMessage="Last name"
                  />
                }
              >
                <InputWrapperFormik Field={Field} name="family_name">
                  <InputText
                    isPrivate
                    id="family_name"
                    name="family_name"
                    dataId="AccountLastNameField"
                    placeholder={intl.formatMessage({
                      id: 'lastName',
                      defaultMessage: 'Last name'
                    })}
                  />
                </InputWrapperFormik>
              </Label>

              {user.email && (
                <Label
                  forId="email"
                  variant="bodyS"
                  isRequired
                  value={
                    <FormattedMessageWrapper
                      id="emailAddress"
                      defaultMessage="Email address"
                    />
                  }
                >
                  <InputTextStyledWrapper onClick={onEmailClicked}>
                    <InputWrapperFormik Field={Field} name="email">
                      <InputText
                        isPrivate
                        isVerified
                        disabled
                        id="email"
                        name="email"
                        dataId="AccountEmailField"
                        placeholder={intl.formatMessage({
                          id: 'emailAddress',
                          defaultMessage: 'Email address'
                        })}
                      />
                    </InputWrapperFormik>
                  </InputTextStyledWrapper>
                </Label>
              )}

              {user.phone_number && (
                <Label
                  forId="phone_number"
                  variant="bodyS"
                  isRequired
                  value={
                    <FormattedMessageWrapper
                      id="phoneNumber"
                      defaultMessage="Phone number"
                    />
                  }
                >
                  <InputWrapperFormik Field={Field} name="phone_number">
                    <InputText
                      isPrivate
                      isVerified
                      disabled
                      id="phone_number"
                      name="phone_number"
                      dataId="AccountPhoneField"
                      placeholder={intl.formatMessage({
                        id: 'phoneNumber',
                        defaultMessage: 'Phone number'
                      })}
                    />
                  </InputWrapperFormik>
                </Label>
              )}
            </FormWrapper>

            {!isMobile && (
              <Button
                fullWidth
                dataId="UpdateAccountDetailsCTA"
                type="submit"
                size="lg"
                loading={isSubmitting}
                disabled={isSubmitting}
              >
                <FormattedMessageWrapper
                  id="updatePersonalDetails"
                  defaultMessage="Update personal details"
                />
              </Button>
            )}
          </FormExtended>
        </Formik>
      )}

      {!isMobile ? (
        <>
          <Text variant="titleL">
            <FormattedMessageWrapper
              id="accountContent.deleteAccount"
              defaultMessage="Delete account"
            />
          </Text>

          <DeleteAccountWrapper>
            <Text variant="bodyM">{intl.formatMessage(deleteAccountText)}</Text>

            <DeleteUserProfile />
          </DeleteAccountWrapper>
        </>
      ) : null}
    </Wrapper>
  )
}
