import React, {useCallback, useState} from 'react'
import styled from '@emotion/styled'

import {Flag} from '@daedalus/atlas/Flag'
import {Icon} from '@daedalus/atlas/Icon'
import {Text} from '@daedalus/atlas/Text'
import {cssTheme} from '@daedalus/atlas/themes'
import {useDispatchTeamEvent} from '@daedalus/core/src/analytics/hooks/useDispatchTeamEvent'
import {
  MenuItemComponent,
  useTrackMenuItemClick
} from '@daedalus/core/src/analytics/hooks/useTrackMenuItemClick'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  Category,
  Entity,
  Team
} from '@daedalus/core/src/analytics/types/Events'
import {FormattedMessageWrapper} from '@daedalus/core/src/localization/components/FormattedMessage'
import {getCountryCodeFromLanguage} from '@daedalus/core/src/localization/services/country'
import {getLanguageConfig} from '@daedalus/core/src/localization/services/locale'
import {LanguageType} from '@daedalus/core/src/localization/types/LanguageType'

import {LanguageCurrencySelector} from '../../../../search/LanguageCurrencySelector'
import {IconWrapper, Label, WrapperSelector} from '../commonStyles'

const StyledFlag = styled(Flag)`
  margin-right: ${cssTheme.layout.spacing.s300};
`

const StyledText = styled(Text)`
  max-width: 200px; // fixed width to prevent pushing longer elements
  text-overflow: ellipsis;
  overflow: hidden;
  text-wrap: nowrap;
`

type Props = {
  /** List of languages to be displayed */
  availableLanguages: LanguageType[]
  /** The currently selected value */
  currentLanguage: string
  /** The callback for when a currency is selected. The new currency code will be passed. */
  onChangeLanguage: (code: string) => void
  /** The callback when language input is clicked */
  onClickLanguage?: () => void
}

export const MobileLanguageSelector = ({
  availableLanguages,
  currentLanguage,
  onChangeLanguage,
  onClickLanguage
}: Props) => {
  const dispatchTeamEvent = useDispatchTeamEvent()

  const {trackMenuItemClick} = useTrackMenuItemClick()
  const [isLanguageSelectorOpen, setLanguageSelectorOpen] = useState(false)

  const handleClick = () => {
    trackMenuItemClick(MenuItemComponent.Language, Team.Retention)
    if (onClickLanguage) onClickLanguage()
    setLanguageSelectorOpen(true)
  }

  const handleClickSearchInput = useCallback(
    () =>
      dispatchTeamEvent(
        trackEvent({
          category: Category.User,
          entity: Entity.LanguageSelectorSearchInput,
          action: Action.Clicked
        })
      ),
    [dispatchTeamEvent]
  )

  const flagIcon: string = getCountryCodeFromLanguage(currentLanguage)
  const languageConfig = getLanguageConfig(currentLanguage) as LanguageType

  const languageLabel =
    languageConfig?.displayName || currentLanguage.toUpperCase()

  return (
    <>
      <WrapperSelector onClick={handleClick}>
        <Label>
          <IconWrapper>
            <Icon name="Language" />
          </IconWrapper>
          <Text variant="labelM">
            <FormattedMessageWrapper
              id="languageLabel"
              defaultMessage="Language"
            />
          </Text>
        </Label>

        <Label>
          <StyledFlag size={22} countryCode={flagIcon} />
          <StyledText variant="labelM">{languageLabel}</StyledText>
        </Label>
      </WrapperSelector>
      <LanguageCurrencySelector
        isMobile
        initialSelector="language"
        isOpen={isLanguageSelectorOpen}
        onClose={() => setLanguageSelectorOpen(false)}
        availableLanguages={availableLanguages}
        selectedLanguage={currentLanguage}
        onChangeLanguage={onChangeLanguage}
        hasCurrency={false}
        availableCurrencies={[]}
        selectedCurrency=""
        onChangeCurrency={() => ({})}
        onSearchInputFocus={handleClickSearchInput}
      />
    </>
  )
}
